import React from 'react';
import {createUseStyles} from 'react-jss';
import style from './Footer.style';
import {DATA_URL, IMPRESSUM_PATH} from 'constants/paths';
import logo from 'static/img/logo.svg';

const useStyles = createUseStyles(style);

export default () => {

  const classes = useStyles();


  return (
    <footer className={classes.footer}>
      <div className={classes.logo}>
        <img src={logo} style={{width: '250px'}} alt={''} />
      </div>
      <div className={classes.txt}>Dieser Kurs wurde vom Verein <a href="https://www.openscience.or.at/" alt="">Open Science &#x2015; Lebenswissenschaften im Dialog</a> in Kooperation mit der <a href="https://www.personalized-medicine.at/" title="Home">Österreichischen Plattform für Personalisierte Medizin</a> erstellt.<br /><ul><li><a href={IMPRESSUM_PATH} alt="Impressum">Impressum</a></li><li><a href={DATA_URL} alt="Datenschutz">Datenschutz</a></li></ul></div>
    </footer>
  );
};
