import React, {useState} from 'react';
import {createUseStyles} from 'react-jss';
import style from './Quiz.style';
import { DndProvider } from 'react-dnd';
import {HTML5Backend} from 'react-dnd-html5-backend';
import {TouchBackend} from 'react-dnd-touch-backend';
import MultiBackend, {Preview, TouchTransition, MouseTransition} from 'react-dnd-multi-backend';
import ObjectItem from './Object'
import TargetItem from './Target';

const HTML5toTouch = {
  backends: [{
    backend: HTML5Backend,
    transition: MouseTransition
  }, {
    backend: TouchBackend,
    options: {
      enableMouseEvents: true
    },
    preview: true,
    transition: TouchTransition
  }]
};

const useStyles = createUseStyles(style);
const texts = {
  txt1: {
    a: [5],
    text: 'Einverständnis'
  },
  txt2: {
    a: [7],
    text: 'Folgediagnostik'
  },
  txt3: {
    a: [6],
    text: 'Forschung'
  },
  txt4: {
    a: [4],
    text: 'Reste'
  },
  txt5: {
    a: [3,2],
    text: 'flüssigem Stickstoff'
  },
  txt6: {
    a: [1],
    text: 'biologische Proben'
  },
  txt7: {
    a: [8],
    text: 'Digitalisierung'
  },
  txt8: {
    a: [3,2],
    text: 'Wachsblöcken'
  }
}
export default ({onCompleted, page}) => {
  const classes = useStyles();
  const generatePreview = ({itemType, item, style}) => {
     return <div className={classes.object} style={style}>{item.text}</div>
   };
  const [ txtPlaced, setTxtPlaced ] = useState({});
  const handleDrop = (index, item) => {
    if(texts[item.id].a.includes(index)){
      let newTxtPlaced = {...txtPlaced}
      newTxtPlaced[item.id] = index;
      if(Object.keys(newTxtPlaced).length === Object.keys(texts).length) onCompleted(page)
      setTxtPlaced(newTxtPlaced);
    }
  }

  return (
    <div style={{width: '100%'}}>
      <DndProvider backend={MultiBackend} options={HTML5toTouch}>
        <Preview generator={generatePreview} />
        <p>{Object.keys(txtPlaced).length === Object.keys(texts).length ? '' : 'Schließen Sie die Lücken im folgenden Text mit den passenden Wörtern:'}</p>
        <div style={{ overflow: 'hidden', clear: 'both'}}>
          {Object.keys(texts).filter(key => !txtPlaced[key]).map((key, idx) => {
            return <ObjectItem key={'txt-bx-'+key} id={key} text={texts[key].text} />
          })}
        </div>
        <p style={{fontSize: '16px', lineHeight: '24px'}}>In einer Biobank werden {txtPlaced.txt6 === 1 ? <span className={classes.objectPlaced}>{texts.txt6.text}</span> : <TargetItem onDrop={(item) => handleDrop(1, item)} />} anonymisiert gesammelt. Gewebeproben werden in {txtPlaced.txt8 === 2 || txtPlaced.txt5 === 2 ? <span className={classes.objectPlaced}>{txtPlaced.txt8 === 2 ? texts.txt8.text : texts.txt5.text}</span> : <TargetItem onDrop={(item) => handleDrop(2, item)} />} oder {txtPlaced.txt8 === 3 || txtPlaced.txt5 === 3 ? <span className={classes.objectPlaced}>{txtPlaced.txt8 === 3 ? texts.txt8.text : texts.txt5.text}</span> : <TargetItem onDrop={(item) => handleDrop(3, item)} />} gelagert.</p>
        <p style={{fontSize: '16px', lineHeight: '24px'}}>Gesammelt werden {txtPlaced.txt4 === 4 ? <span className={classes.objectPlaced}>{texts.txt4.text}</span> : <TargetItem onDrop={(item) => handleDrop(4, item)} />}  von Geweben oder Blutproben aus Routineeingriffen, es wird kein zusätzliches Material speziell für die Biobank entnommen. Die Proben dürfen nur mit dem {txtPlaced.txt1 === 5 ? <span className={classes.objectPlaced}>{texts.txt1.text}</span> : <TargetItem onDrop={(item) => handleDrop(5, item)} />} der Patient*innen gelagert werden.  Die Proben der Biobank dienen der biomedizinischen {txtPlaced.txt3 === 6 ? <span className={classes.objectPlaced}>{texts.txt3.text}</span> : <TargetItem onDrop={(item) => handleDrop(6, item)} />} und der medizinischen {txtPlaced.txt2 === 7 ? <span className={classes.objectPlaced}>{texts.txt2.text}</span> : <TargetItem onDrop={(item) => handleDrop(7, item)} />} der Patient*innen. Derzeit wird vor allem an der {txtPlaced.txt7 === 8 ? <span className={classes.objectPlaced}>{texts.txt7.text}</span> : <TargetItem onDrop={(item) => handleDrop(8, item)} />} der Proben gearbeitet.
        </p>
    	</DndProvider>
    </div>
  );
};
