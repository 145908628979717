const bubble = {
  opacity: 0,
  zIndex: 1,
  position: 'relative',
  borderRadius: '8px',
  margin: '8px 16px auto',
  padding: '16px',
  animation: '$fadeIn 1s',
  animationFillMode: 'forwards',
  maxWidth: '400px'
}
const bubbleAfter = {
  content: "''",
  position: 'absolute',
  top: '10px',
  width: 0,
  height: 0,
  border: '20px solid transparent',
  borderBottom: 0,
  marginTop: 0
}

export default {
  bubbleWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    padding: '8px',
    '& img': {
      opacity: 0,
      animation: '$fadeInInfo 1s',
      animationFillMode: 'forwards',
      width: '100%'
    },
    '& div:first-child': {
      width: '20%'
    },
    '& div:nth-child(2)': {
      width: '80%'
    }
  },
  bubble: {
    ...bubble,
  	background: '#c7a5c7',
    marginRight: 'auto',
    color: '#fff',
    '&:after': {
      ...bubbleAfter,
      borderRightColor: '#c7a5c7',
      marginLeft: '-16px',
      borderLeft: 0,
      left: 0
    }
  },
  bubble2: {
    ...bubble,
    background: '#7bdcf7',
    marginLeft: 'auto',
    color: '#000',
    '&:after': {
      ...bubbleAfter,
      borderLeftColor: '#7bdcf7',
      marginRight: '-16px',
      borderRight: 0,
      right: 0
    }
  },
  info: {
    opacity: 0,
    animation: '$fadeInInfo 1s',
    animationFillMode: 'forwards'
  },
  infoBox: {
    opacity: 0,
    animation: '$fadeIn 1s',
    animationFillMode: 'forwards',
    borderRadius: '20px',
    width: '100%',
    minWidth: '260px',
    transition: 'width 1s',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    overflow: 'hidden',
    margin: '50px auto',
    padding: 0,
    '& h3': {
      display: 'flex',
      justifyContent: 'center',
      margin: 0,
      lineHeight: '40px',
      padding: '16px',
      textAlign: 'center',
      backgroundColor: '#5ac3df',
      color: '#fff',
      '& img': {
        marginRight: '8px'
      }
    }
  },
  search: {
    backgroundColor: '#fff',
    zIndex: 1,
    opacity: 0,
    position: 'absolute',
    borderRadius: '50%',
    height: '60px',
    width: '60px',
    top: '50%',
    left: '50%',
    margin: '-30px 0 0 -30px',
    transition: 'opacity .25s',
    '& img': {
      margin: '10px',
      height: '40px',
      width: '40px',
    }
  },
  link: {
    opacity: 0,
    animation: '$fadeIn 1s',
    animationFillMode: 'forwards',
    position: 'relative',
    display: 'block',
    maxWidth: '180px',
    width: '100%',
    margin: '0 auto',
    cursor: 'pointer',
    '&:hover': {
      '& img': {
        transform: 'scale(1.1)',
        transition: 'transform 1s'
      },
      '& $search': {
        opacity: 1
      }
    }
  },
  content: {
    opacity: 0,
    overflow: 'hidden',
    backgroundColor: '#eee',
    padding: '0 8px',
    maxHeight: '40px',
    transition: 'all 1s',
    animation: '$fadeInInfo 1s',
    animationFillMode: 'forwards',
    '& a': {
      backgroundColor: 'rgba(0, 216, 239, .3)',
      color: '#606e77',
      padding: '4px',
      textDecoration: 'none',
    },
    '& a:hover': {
      backgroundColor: 'rgba(0, 216, 239, 1)',
      color: '#fff',
    }
  },
  more: {
    textAlign: 'center',
    cursor: 'pointer',
    transition: 'all 1s',
    '&:hover': {
      transform: 'scale(1.07)'
    },
  },
  footer: {
    backgroundColor: '#5ac3df',
    borderRadius: '20px',
    height: '20px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
    color: '#fff'
  },
  '@keyframes fadeIn': {
    '0%': { opacity: 0, transform: 'scale(.5)' },
    '50%': { opacity: 1, transform: 'scale(1.07)' },
    '100%': { opacity: 1, transform: 'scale(1)' },
  },
  '@keyframes fadeInInfo': {
    '0%': { opacity: 0 },
    '100%': { opacity: 1 }
  }
}
