import React from 'react';
import iconVideo from 'static/img/video.svg';
import iconlist from 'static/img/list.svg';
import imgThermocycler from 'static/img/Thermocycler-Medizintechnik.png';
import imgPcr from 'static/img/pcr.jpg';
import imgIc1 from 'static/img/immunhistochemie1.jpg';
import imgIc2 from 'static/img/immunhistochemie2.jpg';
import imgIc3 from 'static/img/immunhistochemie3.jpg';
import iconDna from 'static/img/dna.svg';
import imgBv1 from 'static/img/bv1.jpg';
import imgBv2 from 'static/img/bv2.jpg';
import imgC2 from 'static/img/c2.png';
import Tooltip from 'components/Tooltip';
import Info from 'components/Tooltip/Info';
// import { Link } from 'react-router-dom';


export default [
  {
    chapter: 2,
    type: 'preview',
    title: 'Einleitung',
    content: <div style={{fontSize: '90%', backgroundImage: 'linear-gradient(to right bottom, #d0d0d0, #d7d7d7, #dfdfdf, #e6e6e6, #eeeeee)', color: '#555', padding: '16px', margin: 0, borderRadius: '16px'}}>
        <div style={{borderBottom: '1px solid #bbb', padding: '16px 0'}}>
          <div style={{display: 'flex', justifyContent: 'center'}}><img style={{maxWidth: '130px', maxHeight: '130px'}} src={imgC2} alt="" /></div>
          <h1 style={{fontSize: '140%', margin: '24px 0 16px', overflowWrap: 'break-word', wordWrap: 'break-word', textAlign: 'center', color: '#44555f'}}>Personalisierte Medizin in der Praxis</h1>
        </div>
      <p>Hier bekommen Sie einen Einblick in die Ansätze und Methoden der Personalisierten Medizin. </p>
      <p>Sie erfahren außerdem:</p>
      <ul style={{padding:0, listStyle: 'none'}}>
      <li style={{display: 'flex', padding: '8px 0'}}><img src={iconlist} style={{margin: '0 8px auto 0'}} alt=""/>Welche Technologien in der Personalisierten Medizin eingesetzt werden.</li>
      <li style={{display: 'flex', padding: '8px 0'}}><img src={iconlist} style={{margin: '0 8px auto 0'}} alt=""/>Welche Rolle die Genetik bei der Verschreibung von Medikamenten spielt.</li>
      <li style={{display: 'flex', padding: '8px 0'}}><img src={iconlist} style={{margin: '0 8px auto 0'}} alt=""/>Wie verschiedene Disziplinen am Fortschritt der Personalisierten Medizin beteiligt sind.</li>
      </ul>
    </div>
  },
  {
    chapter: 2,
    type: 'text',
    title: 'Ansätze in der Praxis',
    navIcons: [iconVideo],
    navGroup: 'c2g1',
    content: [
      <p>Die Personalisierte Medizin berücksichtigt individuelle Eigenschaften von Patient*innen in der Diagnose, der Therapie und auch in der Prävention.</p>
    ],
    info: [
      {
        title: 'Diagnose',
        content: <p>Um Krankheiten zu erkennen, werden in der Personalisierten Medizin verschiedene diagnostische Technologien eingesetzt. <strong>Gespr&auml;che, bildgebende Verfahren und Laboruntersuchungen</strong> werden herangezogen, um die Krankheit m&ouml;glichst genau zu diagnostizieren und fr&uuml;h zu erkennen.</p>
      },
      {
        title: 'Therapie',
        content: <>
          <p>Anhand von Biomarkern wird die Wirksamkeit einer geplanten Behandlung im Vorhinein gepr&uuml;ft, um die Ansprechraten von Therapien zu erh&ouml;hen und Nebenwirkungen zu minimieren.</p>
          <p>In einer genetischen Analyse kann abgeklärt werden, welches Therapeutikum die beste Wirksamkeit und Verträglichkeit für diese*n Patient*in bietet und in welcher Dosierung es optimal wirkt.</p>
          <p>Ziel der Personalisierten Medizin ist es nicht, f&uuml;r eine*n Patient*in ein pers&ouml;nliches Medikament herzustellen, sondern aus der <strong>Vielzahl an schon verf&uuml;gbaren Wirkstoffen den passendsten auszuw&auml;hlen</strong>.</p>
          <p>Personalisierte Behandlungen werden also f&uuml;r Patient*innen mit &auml;hnlichen molekularen Charakteristika, wie beispielsweise einer bestimmten Genvariante, entwickelt. Diese Art der Gruppierung nennt man auch <strong>Stratifizierung.</strong></p>
          <p>Sie ist vergleichbar mit einer Art Kleidergr&ouml;&szlig;ensystem: Es w&auml;re zu aufw&auml;ndig und nicht n&ouml;tig, f&uuml;r jede Person alle Kleidungsst&uuml;cke ma&szlig;anzufertigen. Deswegen werden mehrere Gr&ouml;&szlig;en produziert, von denen jede*r die passendsten St&uuml;cke ausw&auml;hlt. F&uuml;r bestimmte F&auml;lle k&ouml;nnen aber immer noch Ma&szlig;anfertigungen vorgenommen werden.</p>
        </>
      },
      {
        chapter: 2,
        title: 'Prävention',
        content: <>
          <p>Ebenfalls zur Personalisierten Medizin geh&ouml;rt die Pr&auml;vention von Krankheiten.</p>
          <p>Bei der vorhersagenden (prädiktiven) genetischen Diagnostik wird versucht, beim gesunden Menschen Mutationen oder Genvarianten zu finden, um Krankheitsrisiken abzuschätzen und diesen präventiv entgegenzuwirken. Durch die Beobachtung von geeigneten Indikatoren (Biomarkern) kann der Verlauf von Krankheiten abgeschätzt und entsprechend gegengesteuert werden.</p>
          <p>Krebsrisiken zu ermitteln und Ver&auml;nderungen zu beobachten ist schon heute medizinische Alltagspraxis, wenn auch nicht immer mit genetischen Untersuchungen.</p>
          <p>Methoden zur Vorsorge sind beispielsweise Darmspiegelungen oder Muttermalkontrollen. Auch in der Familie vorkommende Erbkrankheiten können im Rahmen der Prävention eine Rolle spielen.</p>
        </>
      },
    ]
  },
  {
    chapter: 2,
    type: 'video',
    icon: iconVideo,
    isSub: true,
    navGroup: 'c2g1',
    title: 'Video: Praxiseinblick',
    url: ['https://www.youtube.com/watch?v=Wvim_Ut32As'],
    description: 'Barbara Obermayer-Pietsch, Leiterin der Endokrinologie - Laborplattform, spricht über Personalisierte Ansätze in Diagnose und Therapie von polyzystischem Ovar-Syndrom.'
  },
  {
    chapter: 2,
    type: 'text',
    id: 'diagTech',
    title: 'Diagnostische Technologien',
    navGroup: 'c2g2',
    navIcons: [iconVideo],
    content: <>
      <p>Für die Diagnose und Behandlung von Krankheiten sind molekulare Verfahren und Technologien heute nicht mehr wegzudenken. Dafür bedarf es unterschiedlicher Ausstattung – vom einfachen Labor bis hin zu hoch technologisierten Kompetenzzentren. In der Personalisierten Medizin sind vor allem Laboranalysen von großer Bedeutung.</p>
      <p>Die häufigsten diagnostischen Technologien der Personalisierten Medizin im Überblick:</p>
    </>,
    info: [
      {
        title: 'PCR',
        content: <>
          <p>Um genetische Analysen vornehmen zu k&ouml;nnen, muss zun&auml;chst <strong>DNA</strong> (oder RNA) aus Blut, Speichel oder anderen K&ouml;rperproben isoliert werden.</p>
          <p>Nach der Aufreinigung der DNA wird diese mittels PCR (polymerase chain reaction, Polymerase Kettenreaktion) vervielfältigt.  Um die vervielfältigte DNA anschließend zu analysieren, kommen verschiedene Methoden zum Einsatz:  Mithilfe der RT-qPCR (real-time quantitative PCR) wird beispielsweise mithilfe von Fluoreszenz die Menge (Quantität) der DNA während der Vervielfältigung in Echtzeit gemessen.</p>
          <div style={{display: 'flex', justifyContent: 'center', flexDirection: 'column'}}>
            <img src={imgThermocycler} alt="Thermocycler-Medizintechnik, Bildautor: Wirthwein AG" style={{margin: '32px auto 0', maxWidth: '480px', width: '80%'}}/>
            <div style={{textAlign: 'center', fontSize: '10px', marginBottom: '32px'}}>Thermocycler-Medizintechnik, Autor: Wirthwein AG</div>
          </div>
          <p>Die wichtigsten Einsatzgebiete der PCR in der Diagnostik sind: </p>
          <ul>
            <li>Abklärung von genetisch bedingten Erkrankungen,</li>
            <li>Untersuchung von Mutationen bei Tumorerkrankungen oder</li>
            <li>Diagnose und Verlaufskontrollen von Infektionskrankheiten.<i>[5]</i></li>
          </ul>
          <div style={{display: 'flex', justifyContent: 'center', flexDirection: 'column'}}>
            <img src={imgPcr} alt="Thermocycler, Bildautor: Manon Chauvin" style={{margin: '32px auto 0', maxWidth: '480px', width: '80%'}}/>
            <div style={{textAlign: 'center', fontSize: '10px', marginBottom: '32px'}}>Thermocycler, Autor: Manon Chauvin</div>
          </div>
        </>
      },
      {
        title: 'Histologische Diagnostik',
        content: <>
          <Tooltip />
          <p>Die am meisten verbreitete Methode der Krebsdiagnostik ist die <span className="tooltip" data-tip="Histologie ist die Wissenschaft von den Geweben des menschlichen Körpers">histologische<Info /></span> Untersuchung von Tumorgewebe.</p>
          <div style={{display: 'flex', justifyContent: 'center', flexDirection: 'column'}}>
            <img src={imgIc1} alt="Snap freezingin in Flüssigstickstoff, Autor: Inst. f Pathologie, Med Uni Graz" style={{margin: '32px auto 0', maxWidth: '480px', width: '80%'}}/>
            <div style={{textAlign: 'center', fontSize: '10px', marginBottom: '32px'}}>Snap freezing in Flüssigstickstoff, Autor: Inst. f Pathologie, Med Uni Graz</div>
          </div>
          <p>Im Zuge einer Biopsie wird ein Stück Gewebe entnommen. Dieses wird in Formalin fixiert, in Paraffin eingebettet und anschließend in hauchdünne Scheiben geschnitten, welche einen Querschnitt des Gewebes darstellen. </p>
          <div style={{display: 'flex', justifyContent: 'center', flexDirection: 'column'}}>
            <img src={imgIc3} alt="FFPE Block4, Autor: BBMRI.at, Cornelia Stumptner" style={{margin: '32px auto 0', maxWidth: '480px', width: '80%'}}/>
            <div style={{textAlign: 'center', fontSize: '10px', marginBottom: '32px'}}>FFPE Block4, Autor: BBMRI.at, Cornelia Stumptner</div>
          </div>
          <p>Darauf folgt die immunhistochemische Analyse: Mit Farbmolekülen gekoppelte Antikörper sind unter dem Mikroskop als Farbpunkte sichtbar, wenn sie an bestimmte Tumor-spezifische Proteine binden und lassen mögliche Rückschlüsse auf die Tumoreigenschaften zu.</p>
          <p>Unter dem Mikroskop sind die Bindungen als Farbpunkte erkennbar und ermöglichen Rückschlüsse auf Tumoreigenschaften.</p>
          <div style={{display: 'flex', justifyContent: 'center', flexDirection: 'column'}}>
            <img src={imgIc2} alt="Melanomzellen, Autor: National Cancer Institute" style={{margin: '32px auto 0', maxWidth: '480px', width: '80%'}}/>
            <div style={{textAlign: 'center', fontSize: '10px', marginBottom: '32px'}}>Melanomzellen, Autor: National Cancer Institute</div>
          </div>
        </>
      },
      {
        title: 'Bildgebende Verfahren',
        content: <>
          <div style={{display: 'flex', justifyContent: 'center', flexDirection: 'column'}}>
            <img src={imgBv1} alt="MRI, Autor: National Cancer Institute" style={{margin: '32px auto 0', maxWidth: '480px', width: '80%'}}/>
            <div style={{textAlign: 'center', fontSize: '10px', marginBottom: '32px'}}>MRI, Autor: National Cancer Institute</div>
          </div>
          <p>Unter bildgebenden Verfahren werden Untersuchungsmethoden zusammengefasst, die <strong>Bilddaten von K&ouml;rperstrukturen</strong> liefern und zur Diagnostik eingesetzt werden.</p>
          <p>Moderne Bildgebungsverfahren erm&ouml;glichen es, Organe, aber auch ganze K&ouml;rperabschnitte abzubilden, und so beispielsweise ein <strong>Gesamtbild einer Tumorerkrankung</strong> zu erstellen. Dabei verwendete Technologien sind unter anderem R&ouml;ntgentechnologien, Radionuklidtechnologien, Ultraschall, Kernspintomografie und optische Tomographien.</p>
          <p>Durch die Erstellung von zwei- oder dreidimensionalen Bildern k&ouml;nnen <strong>biologische Strukturen spezifisch sichtbar</strong> gemacht werden.</p>
          <div style={{display: 'flex', justifyContent: 'center', flexDirection: 'column'}}>
            <img src={imgBv2} alt="PET-Hirnaufnahme, Bildautor: Jens Maus" style={{margin: '32px auto 0', maxWidth: '480px', width: '80%'}}/>
            <div style={{textAlign: 'center', fontSize: '10px', marginBottom: '32px'}}>PET-Hirnaufnahme (axiales Schnittbild), Autor: Jens Maus</div>
          </div>
        </>
      }
    ],
    cit: ['5']
  },
  {
    chapter: 2,
    type: 'video',
    isSub: true,
    navGroup: 'c2g2',
    icon: iconVideo,
    title: 'Video: Diagnostik',
    description: 'Renate Kain, Leiterin des klinischen Instituts für Pathologie der Meduni Wien, erklärt, wie Gewebeproben verarbeitet und diagnostiziert werden.',
    url: ['https://www.youtube.com/watch?v=O5o7Re9Upx4']
  },
  {
    chapter: 2,
    type: 'text',
    navGroup: 'c2g3',
    navIcons: [iconVideo],
    title: 'Zusammenarbeit',
    content: <>
      <p>Um ausgehend von Biomarkern pr&auml;zise R&uuml;ckschl&uuml;sse f&uuml;r Therapien ziehen zu k&ouml;nnen, ist noch viel <strong>molekularbiologische und genetische Forschung notwendig</strong>. Der Ansatz der Personalisierten Medizin erfordert ein enges Zusammenspiel unterschiedlicher Expertisen – von biomedizinischer Grundlagenforschung über die verschiedenen medizinischen Fachrichtungen bis zur Entwicklung neuer Technologien.</p>
      <p>Um Personalisierte Medizin bestmöglich in unser Gesundheitssystem zu integrieren, sind aber noch weitere Disziplinen eingebunden: Die Gesundheitsökonomie beschäftigt sich beispielsweise mit den Kosten und der Finanzierung von Personalisierter Medizin. Sozialwissenschaften beleuchten gesellschaftliche und gesellschaftspolitische Auswirkungen. Besonders wichtig ist auch die Mitgestaltung durch die Betroffenen selbst – gerade bei ethischen oder gesellschaftlichen Fragestellungen.</p>
      <p>Die Zusammenarbeit von Forschung und Praxis ist deshalb besonders wichtig, um zu einer differenzierten Bewertung der <strong>Chancen, Risiken und Perspektiven</strong> der Personalisierten Medizin zu gelangen. In Österreich stellt die <strong>Österreichische Plattform für Personalisierte Medizin</strong> (ÖPPM) eine Vernetzungsstelle für alle diejenigen dar, die an der Entwicklung der Personalisierten Medizin interessiert und beteiligt sind.</p>
    </>
  },
  {
    chapter: 2,
    type: 'video',
    title: 'Video: Die ÖPPM',
    icon: iconVideo,
    isSub: true,
    navGroup: 'c2g3',
    url: ['https://www.youtube.com/watch?v=xPDVIWne_iA'],
  },
  {
    chapter: 2,
    type: 'questions',
    icon: iconDna,
    title: 'Quizfrage',
    description: <p>Zu welchem Zeitpunkt einer Erkrankung kommt Personalisierte Medizin zum Einsatz?</p>,
    questions: [
      { question: 'Diagnose der Krankheit', isTrue: true },
      { question: 'Therapie und Behandlung', isTrue: true},
      { question: 'Prävention von Krankheiten', isTrue: true },
      { question: 'Notaufnahme im Krankenhaus', isTrue: true}
    ]
  }
]
