import React, {useState,useEffect} from 'react';
import { Link, useParams } from 'react-router-dom';
import {createUseStyles} from 'react-jss';
import {CONTENT_ROOT_PATH} from 'constants/paths';
import style from './Menu.style';
import pm from 'static/img/pm.png';
import iconCheck from 'static/img/content-check.svg';
import iconContent from 'static/img/content.svg';
import iconChapter from 'static/img/chapter.svg';
import {chapters, chapterData} from 'constants/chapters';

const useStyles = createUseStyles(style);


export default ({open, nvClick, setNv, completed, resetCompleted}) => {
  const { page=1 } = useParams();
  const classes = useStyles();
  const [exp, setExp] = useState({});
  const currentElement = chapters[page-1];
  const currentChapter = currentElement.chapter;

  useEffect(() => {
    setExp({});
  }, [currentChapter])

  return <div className={classes.nav}>
      <div className={classes.header}>
        <img src={pm} style={{width: '120px'}} alt="" />
        <h1 style={{marginBottom: '20px'}}>Personalisierte Medizin Onlinekurs</h1>
      </div>
      <ul>
      {chapterData.map(cap => {
        return <li key={'cap-'+cap.id}>
            <h2 style={{cursor: cap.chapter === currentChapter ? 'default' : 'pointer'}} onClick={() => {
              let newExp = {...exp};
              newExp[cap.id] = newExp[cap.id] ? false : true;
              setExp(newExp);
            }}>{cap.title}</h2>
            {(currentChapter === cap.chapter || exp[cap.id]) &&
          <ul>
          {chapters.map((el, elIdx) => {
              let elIsActive = false;
              let elIsCompleted = completed[elIdx];
              if(Number(page) === elIdx+1) elIsActive = true;

              return el.chapter === cap.chapter && (!el.isSub || el.navGroup === currentElement.navGroup) ?
                <li key={'elmt-'+elIdx} style={el.chapter === cap.chapter ? {opacity: 1, padding: '4px'} : {animationDelay: elIdx*.1+.1+'s', padding: '4px'}} className={elIsActive ? classes.active : null}>
                  <Link style={{display: 'flex', padding: el.isSub ? '0 4px 0 32px' : '0 4px 0 16px' }} onClick={() => {
                    if(!elIsActive) nvClick()
                  }} className={classes.navItem} to={CONTENT_ROOT_PATH + (elIdx + 1)}>
                    <div className={classes.label} style={elIsCompleted ? {color: elIsCompleted} : {}}>
                      <span>{el.title}</span>
                      {el.icon && <img style={{backgroundColor: '#00d8ef', borderRadius: '50%', height: '16px', width: '16px', padding: '4px', marginLeft: '6px'}} alt="" src={el.icon} />}
                      {el.navIcons && el.navGroup !== currentElement.navGroup && el.navIcons.map((icon, iconIdx) => <img style={{backgroundColor: '#00d8ef', borderRadius: '50%', height: '16px', width: '16px', padding: '4px', marginLeft: '6px'}} alt="" src={icon} key={'icon-'+elIdx+'-'+iconIdx} />)}
                    </div>
                    {elIsCompleted ? <img className={classes.checked} style={{height: '16px', margin: '3px 0 0 8px'}} src={iconCheck} alt="" /> : <img className={classes.checked} style={{height: '16px', marginLeft: '8px'}} src={iconContent} alt="" />}
                  </Link>
                </li> : null;
            })}
          </ul>}
        </li>
      })}
      </ul>
      <ul style={{margin: '20px 0 40px'}}>
        <li>
          <ul style={{margin: 0}}>
            <li style={{opacity: 1, padding: '4px'}}>
              <Link onClick={() => {
                nvClick()
              }} style={{padding: '0 4px 0 4px', display: 'flex', justifyContent:'center'}} className={classes.navItem} to={CONTENT_ROOT_PATH + (chapters.length - 1)}>
                <div className={classes.label}>
                  <span style={{color: '#a1a9af'}}>Kapitelübersicht</span>
                  <img style={{backgroundColor: '#a1a9af', borderRadius: '50%', height: '16px', width: '16px', padding: '4px', marginLeft: '6px'}} alt="" src={iconChapter} />
                </div>
              </Link>
            </li>
          </ul>
        </li>
      </ul>
      <div style={{display: 'flex', justifyContent: 'center'}}><img style={{height: '16px', marginLeft: '8px'}} src={iconCheck} alt="" /> <span style={{color: '#a1a9af', fontSize: '12px'}}>Abschnitt abgeschlossen</span></div>
      <div style={{fontSize: '12px', display: 'flex', justifyContent: 'center'}}><span onClick={() => {
        resetCompleted();
      }} style={{margin: '8px 0', cursor: 'pointer'}}>Fortschritt zurücksetzen</span></div>
    </div>
};
