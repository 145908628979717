export default {
  wrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    overflow: 'hidden',
    margin: 0,
    padding: 0,
    '& li': {
      margin: '16px 0',
      backgroundColor: '#fff',
      borderRadius: '20px',
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'column',
      flex: 1
    },
    '& img': {
      borderRadius: '50%',
      border: '8px solid #fff',
      backgroundColor: '#5ac3df',
      margin: '-44px auto 0',
      width: '80px',
      height: '80px'
    },
    '& p': {
      padding: '16px'
    },
    '& h5': {
      padding: 0,
      fontSize: '110%',
      margin: '8px 0 0',
      textAlign: 'center'
    }
  },
  role: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    borderRadius: '20px',
    backgroundColor: '#5ac3df',
    color: '#fff',
    '& p': {
      padding: '16px',
      margin: 0
    }
  },
  header: {
    height: '80px',
    '& h4': {
      margin: 0,
      padding: '16px',
      textAlign: 'center',
      color: '#555'
    }
  }
}
