import React from 'react';
import {createUseStyles} from 'react-jss';
import style from './Header.style';
import logo from 'static/img/oeppm.svg';

const useStyles = createUseStyles(style);

export default () => {

  const classes = useStyles();


  return (
    <header className={classes.header}>
      <div className={classes.logo}>
        <img src={logo} style={{width: '250px'}} alt={'Österreichische Plattform für Personalisierte Medizin'} />
      </div>
    </header>
  );
};
