export default {
  overview: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center'
  },
  target: {
    display: 'inline-block',
    width: '180px',
    height: '24px',
    borderBottom: '1px solid #555',
    margin: 0,
  },
  object: {
    cursor: 'move',
    display: 'inline-block',
    fontSize: '16px',
    lineHeight: '24px',
    textAlign: 'center',
    width: '180px',
    height: '24px',
    border: '1px dashed #999',
    backgroundColor: '#eee',
    margin: '2px'
  },
  objectPlaced: {
    fontSize: '16px',
    lineHeight: '24px',
    textAlign: 'center',
    fontWeight: 600,
    height: '24px',
    backgroundColor: '#fff',
    margin: 0
  },
}
