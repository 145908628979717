import React from 'react';
import { Link } from 'react-router-dom';
import iconGroup from 'static/img/group.svg';
import iconVideo from 'static/img/video.svg';
import iconlist from 'static/img/list.svg';
import iconNews from 'static/img/newspaper.svg';
import iconDna from 'static/img/dna.svg';
import iconDiscuss from 'static/img/forum.svg';
import imgC1 from 'static/img/c1.png';
import imgC4 from 'static/img/c4.png';
import Tooltip from 'components/Tooltip';
import Info from 'components/Tooltip/Info';
const styleLnk = {
  position: 'absolute',
  top: '5px',
  left: '10px',
  width: 0,
  height: 0,
  borderTop: '5px solid transparent',
  borderBottom: '5px solid transparent',
  borderLeft: '5px solid #00d8ef',
}

export default [
  {
    chapter: 4,
    type: 'preview',
    title: 'Einleitung',
    content: <div style={{fontSize: '90%', backgroundImage: 'linear-gradient(to right bottom, #d0d0d0, #d7d7d7, #dfdfdf, #e6e6e6, #eeeeee)', color: '#555', padding: '16px', margin: 0, borderRadius: '16px'}}>
      <div style={{borderBottom: '1px solid #bbb', padding: '16px 0'}}>
        <div style={{display: 'flex', justifyContent: 'center'}}><img style={{maxWidth: '130px', maxHeight: '130px'}} src={imgC4} alt="" /></div>
        <h1 style={{fontSize: '140%', margin: '24px 0 16px', overflowWrap: 'break-word', wordWrap: 'break-word', textAlign: 'center', color: '#44555f'}}>Gesellschaft und Ethik</h1>
      </div>
      <p>Im Kontext der Personalisierten Medizin werden auch gesellschaftliche und ethische Fragen diskutiert.</p>
      <p>In diesem Kapitel werden folgende Themen behandelt:</p>
      <ul style={{padding:0, listStyle: 'none'}}>
      <li style={{display: 'flex', padding: '8px 0'}}><img src={iconlist} style={{margin: '0 8px auto 0'}} alt=""/>Die Kostenverteilung im Gesundheitssystem.</li>
      <li style={{display: 'flex', padding: '8px 0'}}><img src={iconlist} style={{margin: '0 8px auto 0'}} alt=""/>Wie sich gesellschaftliche Ungleichheiten in der Medizin widerspiegeln.</li>
      <li style={{display: 'flex', padding: '8px 0'}}><img src={iconlist} style={{margin: '0 8px auto 0'}} alt=""/>Die Beteiligung von Patient*innen am Fortschritt der Personalisierten Medizin.</li>
      </ul>
    </div>
  },
  {
    chapter: 4,
    type: 'discussion',
    title: 'Hoffnungen und Befürchtungen',
    intro: <>
      <p>Viele Menschen setzen große Hoffnungen in die Personalisierte Medizin. Sie soll dabei helfen, Prävention, Diagnose und Therapie enger an spezifische Eigenschaften von Patient*innen anzupassen. So soll die <strong>Effektivität</strong> von Interventionen zu erhöhen, unnötiges <strong>Leiden zu verhindern</strong>, und im besten Fall auch noch <strong>Kosten zu senken</strong>.</p>
      <p>Gleichzeitig fürchten manche Menschen, dass eine Personalisierte Medizin, die sich hauptsächlich auf molekulare Daten und andere hochtechnologische Analysen verlässt, <strong>andere Aspekte ausblendet</strong>, die für die Behandlung und die Gesundheit wichtig sind.</p>
      <p>Zudem wird die Sorge geäußert, dass der Begriff „Personalisierte Medizin“ bei Patient*innen <strong>falsche Hoffnungen</strong> wecken und diese glauben machen könnte, dass man gerade für ihr Problem eine maßgeschneiderte Lösung finden wird.</p>
      <p>Eine Forderung diesbezüglich ist der stärkere Einbezug von psychischer Gesundheit. Zudem wird die Sorge geäußert, dass der Begriff „Personalisierte Medizin“ bei Patient*innen falsche Hoffnungen wecken und diese glauben machen könnte, dass man gerade für ihr Problem eine maßgeschneiderte Lösung finden wird.</p>
      <p></p>
    </>,
    description: [
      'Ein gemeinsames Verständnis der Möglichkeiten und Herausforderungen ist wichtig für eine breite Diskussion der Personalisierten Medizin.'
    ]
  },
  {
    chapter: 4,
    title: 'Kostenaspekte',
    type: 'text',
    navIcons: [iconGroup],
    navGroup: 'c4g2',
    content: <>
      <Tooltip />
      <p>Ungeklärt ist, wie sich die Kosten der Personalisierten Medizin entwickeln werden und wer diese tragen soll. Forschung und klinische Studien sind sehr kostenintensiv. Ein besserer Behandlungserfolg durch die Anwendung der Personalisierten Medizin kann jedoch im Gegenzug Kosten sparen. Auch wird erwartet, dass die Kosten für manche Untersuchungen in Zukunft sinken werden (z.B. für <span className="tooltip" data-tip="die Bestimmung der Abfolge von Bausteinen biologischer Moleküle. Im Falle der DNA liefert sie somit Daten über Abfolge und Position der Basenpaare in der DNA und dient der Entschlüsselung des Genoms.">Sequenzierungen<Info /></span>).</p>
      <p>Die zukünftige Finanzierung der Personalisierten Medizin wird viel diskutiert und ist an gesundheitspolitische Entscheidungen gekoppelt. Generell brauchen Kostenaspekte im Gesundheitssystem ein stärkeres Bewusstsein, um Finanzierungsfragen in der Gesellschaft diskutieren zu können.</p>
    </>
  },
  {
    chapter: 4,
    type: 'roleplay',
    icon: iconGroup,
    navGroup: 'c4g2',
    isSub: true,
    title: 'Rollenspiel: Zukunftsszenarien',
    description: [
      <p>Zur Zukunft der Personalisierten Medizin und ihre Finanzierung gibt es verschiedene Ansichten und Meinungen. Vier verschiedene, gegensätzliche Standpunkte zum Thema sind hier in vier fiktiven Rollen zusammengefasst. </p>,
      <p>Schlüpfen Sie in eine der Rollen, versetzen Sie sich in ihre Lage und diskutieren Sie. Finden Sie Argumente und Gegenargumente. Wie sieht ihre persönliche Vorstellung der Zukunft für die Personalisierte Medizin aus?</p>
    ],
    scenarios: [
      {
        role: 'Gesundheitspolitikerin',
        roleDescription: 'Sarah ist Gesundheitspolitikerin. Sie setzt sich für internationale Zusammenarbeit und gemeinsame Datenverwertung ein, um die Gesundheitsversorgung zu optimieren. Sie ist überzeugt, dass durch gute Kooperation alle von einem exzellenten öffentlichen Gesundheitssystem profitieren können. Finanziert werden soll das mit Steuererhöhungen. Die Vorbeugung von Krankheiten hat ihrer Meinung nach Priorität, alle Menschen sollen verpflichtet werden, regelmäßig zu Vorsorgeuntersuchungen zu gehen.'
      },
      {
        role: 'Privater Investor',
        roleDescription: 'Ralf ist ein privater Investor. Er spricht  sich gegen das staatliche Gesundheitssystem aus, weil aufgrund der alternden Bevölkerung die Kosten stark gestiegen sind. Für ihn ist Gesundheit Privatangelegenheit. Patient*innen sollen sich privat versichern und für ihre gewünschten Behandlungen bezahlen. Diejenigen, die sich das nicht leisten können, haben die Möglichkeit, ihre Gesundheitsdaten an Firmen oder Versicherungen zu verkaufen. Ralf meint, dass Pharmafirmen mit den Einnahmen ihrer Medikamente in medizinische Forschung investieren sollen.'
      },
      {
        role: 'Skeptischer Patient',
        roleDescription: 'Heinz war im Zuge seines letzten Krankenhausaufenthaltes von einer Datenschutzverletzung betroffen. Seitdem hat er das Vertrauen in das datenbasierte Gesundheitssystem verloren und meint, dieses sei  durch die technischen Neuerungen und datenbasierten Behandlungen überlastet. Heinz ist nicht bereit, seine Gesundheitsdaten für die Forschung zur Verfügung zu stellen. Er möchte zurück zu der Zeit, als die Medizin noch weniger datenbasiert war und er persönlich von seiner Hausärztin betreut wurde.'
      },
      {
        role: 'Medizintechnikerin',
        roleDescription: 'Claudia arbeitet als Medizintechnikerin an der Entwicklung von Sensoren. Diese sollen, eingebaut in Smartwatches, die Gesundheitsinformationen von Patient*innen aufzeichnen und an ihre Ärzt*innen weiterschicken. So ist eine flexible Gesundheitsversorgung möglich, und Krankheiten können sehr früh erkannt und vorgebeugt werden. Claudia meint, dass in Zukunft künstliche Intelligenz und Algorithmen Patient*innen beraten und so Ärzt*innen viel Arbeit abnehmen werden. Sie ist sich sicher, dass vom technologischen Fortschritt und der freiwilligen Weitergabe von Gesundheitsdaten alle profitieren können. Finanziert werden soll das ihrer Meinung nach durch öffentliche und private Gelder.'
      }
    ]
  },
  {
    chapter: 4,
    type: 'newspaper',
    title: 'Ungleichheiten',
    icon: iconNews,
    intro: <>
      <p>In der Personalisierten Medizin beruht die Diagnose von Krankheiten auch auf dem Vergleich von Labordaten der Patient*innen mit großen Datenbanken (<Link to={'/seite/13'} style={{position: 'relative', paddingLeft: '20px'}}><span style={styleLnk}></span>Biobanken</Link>). Ein wichtiger Punkt dabei ist die Gruppierung von Patient*innen nach<Link to={'/seite/11'} style={{position: 'relative', paddingLeft: '20px'}}><span style={styleLnk}></span>Biomarkern</Link>. Deshalb sind vor allem die Daten von Personen mit ähnlichen Parametern, wie z.B. Alter, Lebensweise und Genvariante, besonders relevant.</p>
      <p>Jedoch sind individuelle Unterschiede in der Bevölkerung nicht gleich verteilt. In Österreich leben beispielsweise überwiegend Personen mit europäischen Wurzeln; bestimmte Genvarianten sind daher verbreiteter als andere. Darüber hinaus gibt es von bestimmten Gruppen mehr medizinische Daten als von anderen.</p>
      <p>Zum Beispiel wurden lange Zeit Studien zu Wirkung und Nebenwirkungen von Medikamenten vorwiegend an Männern durchgeführt – einerseits, um ungeborene Kinder zu schützen, aber andererseits auch, weil man nicht wusste, dass Krankheiten bei Frauen und Männern unterschiedlich verlaufen oder Frauen und Männer unterschiedlich auf Medikamente reagieren. Von Kindern oder schwangeren Frauen gibt es weniger Daten, da diese als besonders schützenswert gelten und nur unter besonderen Voraussetzungen in klinische Studien einbezogen werden können.</p>
      <p>Vorhandene Datensätze sind daher nicht für alle Menschen gleich aussagekräftig. Das könnte darauf hinauslaufen, dass Menschen aus Minderheitsgruppen aufgrund fehlender Datensätze weniger von der personalisierten Medizin profitieren. Gesellschaftliche Ungleichheiten, wie etwa der erschwerte Zugang zum Gesundheitssystem für manche Gruppen, könnten sich so weiter verschärfen. Das Problem der Versorgungsgerechtigkeit im Gesundheitssystem stellt die Personalisierte Medizin also vor Herausforderungen.</p>
      <p><strong>Lesetipp: Ungleichheiten im Gesundheitssystem</strong></p>
    </>,
    content: [
      {
        title: 'Ungleichheit ist ungesund.',
        newspaper: 'Frankfurter Allgemeine Zeitung',
        author: 'Hans W.Höpp',
        date: '15.05.2018',
        link: 'https://www.faz.net/aktuell/politik/inland/soziale-ungleichheit-beeinflusst-die-gesundheit-15585325.html'
      },
      {
        title: 'US-Zulassungsstudien: Minderheiten sind unterrepräsentiert.',
        newspaper: 'Pharmazeutischen Zeitung',
        author: 'Annette Mende',
        date: '20.08.2019',
        link: 'https://www.pharmazeutische-zeitung.de/minderheiten-sind-unterrepraesentiert/'
      },
      {
        title: 'Europa kommt im Kampf gegen gesundheitliche Ungleichheit nicht voran.',
        newspaper: 'Ärzteblatt',
        author: 'hil',
        date: '10.09.2019',
        link: 'https://www.aerzteblatt.de/nachrichten/105879/Europa-kommt-im-Kampf-gegen-gesundheitliche-Ungleichheit-nicht-voran'
      }
    ],
    linkstitle: 'Mehr zum Thema "Biomarker" und "Biobanken" finden Sie hier:',
    chapterLinks: [
      {icon: imgC1, title: [{page: 1, title: 'Grundlagen'}, {page: 11, title: 'Biomarker'}]},
      {icon: imgC1, title: [{page: 1, title: 'Grundlagen'}, {page: 13, title: 'Biobanken'}]}
    ]
  },
  {
    chapter: 4,
    type: 'text',
    navGroup: 'c4g3',
    navIcons: [iconVideo],
    title: 'Beteiligung von Patient*innen',
    content: <>
      <p>Die Perspektive von Patient*innen ist gerade bei Diskussionen rund um gesellschaftliche und ethische Aspekte besonders wichtig. Es gibt verschiedene Möglichkeiten, wie sich Patient*innen direkt an der Planung, Forschung und Umsetzung der Personalisierten Medizin beteiligen können. Ihre Bedürfnisse und Erfahrungen sind eine wertvolle Quelle für die Verbesserung medizinischer Abläufe.</p>
      <p>Eine große Rolle spielt dabei auch die Vertretung der Interessen im Rahmen von Patient*innenvertretungen. Die Europäische Patientenakademie (EUPATI) organisiert beispielsweise Aus- und Weiterbildungen für Patient*innen für ein besseres Verständnis von und Beteiligung an medizinischer Forschung und Entwicklung.</p>
      <p>Überdies leisten Selbsthilfegruppen und Interessensvertretungen einen wertvollen Beitrag zur Stärkung von Patient*innenrechten. Auch in der Aufklärung und Verbreitung von Information rund um Personalisierte Medizin spielen diese Organisationen eine wichtige Rolle.</p>
    </>
  },
  {
    chapter: 4,
    icon: iconVideo,
    navGroup: 'c4g3',
    isSub: true,
    type: 'video',
    title: 'Video: Beteiligung von Patient*innen',
    description: 'Irene Promussas hat im Zuge der Krankheit ihrer Tochter die Patient*innenvertretung Lobby4Kids gegründet. Hier erzählt sie ihre Geschichte.',
    url: ['https://www.youtube.com/watch?v=cVvD8kt33Hs']
  },
  {
    chapter: 4,
    type: 'text',
    title: 'Fazit',
    content: <>
      <p>Die Personalisierte Medizin spielt aktuell in der medizinischen Forschung eine wichtige Rolle. Die Idee dahinter ist jedoch nicht neu. Diagnose und Therapie basieren seit jeher auf Faktoren wie Geschlecht, Alter und Krankheitsgeschichte der jeweiligen Person. Neu sind hingegen die <strong>technologischen Möglichkeiten</strong>, um molekularbiologische Informationen zu gewinnen. Individuelle biologische Merkmale, wie beispielsweise eine Gensequenz, werden <strong>Biomarker</strong> genannt. Anhand von Biomarkern können Schlüsse über Verlauf von Krankheiten und die Wirksamkeit von Therapien gezogen werden.</p>
      <p>Um Fortschritte in der Forschung zur Personalisierten Medizin zu ermöglichen, werden Daten und biologische Proben wie Blut oder Gewebe benötigt. Diese werden im Zuge von Routineeingriffen gesammelt und in sogenannten <strong>Biobanken</strong> gelagert.</p>
      <p>Der Weg hin zur Personalisierten Medizin erfolgt in Schritten. Heute befindet sich die Personalisierte Medizin zum Großteil noch in der <strong>Forschungs- und Entwicklungsphase</strong>. Die meisten personalisierten Behandlungen finden im Rahmen von klinischen Studien statt. Das heißt, nur wenn eine Person nicht auf eine Standardtherapie anspricht und sie einen Platz in einer klinischen Studie bekommt, kann sie als Testperson eine personalisierte Therapie erhalten.</p>
      <p>Obwohl die Personalisierte Medizin noch am Anfang ihrer Entwicklung steht, wirft der Ansatz der Personalisierung schon heute eine Reihe von Fragen auf. Das betrifft zum Beispiel den Umgang mit gesammelten <strong>Daten</strong>, die Entwicklung der <strong>Kosten</strong> sowie die (Über)forderung von <strong>Patient*innen</strong> mit neuen Technologien. Zur Beantwortung dieser Fragen sind sowohl die <strong>Zusammenarbeit von Expert*innen aus verschiedenen Disziplinen</strong> als auch der <strong>Dialog mit Patient*innen</strong> von essenzieller Bedeutung.</p>
    </>
  },
  {
    chapter: 4,
    type: 'questions',
    icon: iconDna,
    title: 'Quizfrage',
    description: <p>Welche gesellschaftlichen Themen werden im Zusammenhang mit der Personalisierten Medizin diskutiert?</p>,
    questions: [
      { question: 'Verteilung von Gesundheitskosten', isTrue: true },
      { question: 'Umweltschutz' },
      { question: 'Datenschutz', isTrue: true },
      { question: 'Ungleichheiten im Gesundheitssystem', isTrue: true}
    ]
  },
  {
    chapter: 4,
    type: 'discussion',
    icon: iconDiscuss,
    title: 'Diskussion: Informationsstand',
    description: [
      'Vergleichen Sie Ihren jetzigen Informationsstand über Personalisierte Medizin mit dem zu Beginn dieses Kurses.',
      'Was haben Sie dazu gelernt?'
    ]
  }
]
