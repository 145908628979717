export default {
  contentWrapper: {
    flex: 1,
    position: 'relative',
    margin: 0,
    display: 'flex',
    flexDirection: 'row',
    overflow: 'hidden',
    padding: '0 50px',
    '&:after': {
      content: '""',
      position: 'absolute',
      left: 0,
      top: 0,
      bottom: 0,
      width: '10px',
      backgroundColor: '#00d8ef'
    },
    '& h1': {
      lineHeight: '50px',
      fontSize: '42px',
      fontWeight: 300
    },
  },
  breadcrumb: {
    color: '#44545F',
    margin: 0,
    '& ul': {
      display: 'flex',
      flexDirection: 'row',
      listStyle: 'none',
      margin: '16px 0',
      padding: 0
    },
    '& li': {
      position: 'relative',
      padding: '0 28px 0 5px',
      lineHeight: '21px',
      fontSize: '14px'
    },
    '& a': {
      fontFamily: 'MuseoSans-700',
      color: '#44545F',
      textDecoration: 'none',
      lineHeight: '21px',
      fontSize: '14px'
    },
    '& a:hover': {
      color: 'rgb(0, 216, 239)'
    },
    '& a:after': {
      position: 'absolute',
      top: '5px',
      right: '10px',
      width: 0,
      height: 0,
      borderTop: '5px solid transparent',
      borderBottom: '5px solid transparent',
      borderLeft: '5px solid #00d8ef',
      content: '""'
    }
  },
  icons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    '& div:hover': {
      backgroundColor: '#555',
    },
    '& div': {
      height: '60px',
      width: '60px',
      margin: '0 8px',
      backgroundColor: 'rgb(0, 216, 239)',
      borderRadius: '50%',
      '& img' : {
        margin: '10px',
        height: '40px',
        width: '40px'
      }
    }
  },
  downloads: {
    listStyle: 'none',
    '& a': {
      display: 'flex'
    }
  },
  logos: {
    display: 'flex',
    justifyContent: 'space-between',
    '& li': {
      display: 'flex',
      flexDirection: 'column',
      width: '30%',
      fontSize: '14px',
      textAlign: 'center'
    },
    '& img': {
      width: '100%',
      margin: '0 auto 16px',
      maxWidth: '200px'
    }
  },
  info: {
    listStyle: 'none',
    padding: 0,
    margin: 0,
    '& li': {
      margin: '8px 0'
    },
    '& h4': {
      cursor: 'pointer',
      margin: 0,
      display: 'flex',
      justifyContent: 'space-between',
      borderRadius: '20px',
      padding: '8px 16px',
      backgroundColor: 'rgba(0, 216, 239, 1)',
      color: 'rgba(255, 255, 255, 1)',
      transition: 'all 1s',
      overflow: 'hidden',
      '&:hover': {
        backgroundColor: 'rgba(0, 216, 239, 1)',
        color: 'rgba(255, 255, 255, 1)',
      },
      '& img': {
        marginRight: '10px'
      },
    },
    '& div': {
      transition: 'opacity .5s, max-height .5s',
      overflow: 'hidden',
    }
  },
  content: {
    margin: 0,
    position: 'relative',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    fontSize: '130%',
    padding: '0',
    width: '100%'
  },
  btn: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textDecoration: 'none',
    backgroundColor: '#44555f',
    fontFamily: 'MuseoSans-700',
    fontWeight: 700,
    cursor: 'pointer',
    color: '#fff',
    margin: '0 auto',
    padding: '10px 20px',
    height: '40px',
    lineHeight: '30px',
    borderRadius: '30px',
    transition: 'all .4s',
    '&:hover': {
      color: '#eee',
      transform: 'scale(1.1)'
    }
  },
  '@media (max-width: 600px)': {
    logos: {
      flexDirection: 'column',
      '& li': {
        paddingBottom: '40px',
        width: '100%',
      }
    },
    contentWrapper: {
      padding: '0 20px 0',
      '&:after': {
        display: 'none'
      }
    },
    icons: {
      '& div': {
        height: '50px',
        width: '50px',
        '& img' : {
          margin: '10px',
          height: '30px',
          width: '30px'
        }
      }
    },
    breadcrumb: {
      '& ul': {
        margin: '15px 0 10px'
      }
    }
  }
}
