const navIcon = {
  zIndex: 10,
  color: '#44545F',
  position: 'absolute',
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'center',
  textDecoration: 'none',
  transition: 'background-color 1s',
  lineHeight: '40px',
  '& img': {
    cursor: 'pointer',
    marginTop: '10px',
    width: '30px',
    height: '30px'
  },
  '&:hover': {
    color: 'rgb(0, 216, 239)',
    '& img': {
      fill: 'rgb(0, 216, 239)'
    }
  }
}

export default {
  contentWrapper: {
    flex: 1,
    position: 'relative',
    margin: 0,
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    overflow: 'hidden',
    '&:after': {
      content: '""',
      position: 'absolute',
      left: 0,
      top: 0,
      bottom: 0,
      width: '10px',
      backgroundColor: '#00d8ef'
    }
  },
  breadcrumb: {
    color: '#44545F',
    margin: 0,
    '& ul': {
      display: 'flex',
      flexDirection: 'row',
      listStyle: 'none',
      margin: '16px 0',
      padding: 0
    },
    '& li': {
      position: 'relative',
      padding: '0 28px 0 5px',
      lineHeight: '21px',
      fontSize: '14px'
    },
    '& a': {
      fontFamily: 'MuseoSans-700',
      color: '#44545F',
      textDecoration: 'none',
      lineHeight: '21px',
      fontSize: '14px'
    },
    '& a:hover': {
      color: 'rgb(0, 216, 239)'
    },
    '& a:after': {
      position: 'absolute',
      top: '5px',
      right: '10px',
      width: 0,
      height: 0,
      borderTop: '5px solid transparent',
      borderBottom: '5px solid transparent',
      borderLeft: '5px solid #00d8ef',
      content: '""'
    }
  },
  icon: {
    height: '60px',
    width: '60px',
    margin: '0 10px 0 0',
    backgroundColor: 'rgb(0, 216, 239)',
    borderRadius: '50%',
    '& img' : {
      margin: '10px',
      height: '40px',
      width: '40px'
    }
  },
  cntnWrapper: {
    maxWidth: '960px',
    width: '100%',
    margin: '0 auto',
    padding: 0
  },
  content: {
    position: 'relative',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    fontSize: '130%',
    padding: '0 44px',
    width: '100%'
  },
  header: {
    display: 'flex',
    justifyContent: 'flex-start',
    margin: 0
  },
  btnWrapper: {
    margin: '50px 0 0',
    width: '100%',
    display: 'flex',
    position: 'relative',
    justifyContent: 'space-between'
  },
  btn: {
    textDecoration: 'none',
    backgroundColor: '#00d8ef',
    color: '#fff',
    padding: '8px 20px',
    height: '30px',
    lineHeight: '30px',
    borderRadius: '30px',
    '&:hover': {
      color: '#fff',
      backgroundColor: '#44555f',
    }
  },
  intro: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    '& h1, h2': {
      textAlign: 'center'
    },
    '& img': {
      maxWidth: '400px',
      margin: '0 auto'
    }
  },
  openIcon: {
    ...navIcon,
    borderRadius: '50%',
    width: '50px',
    height: '50px',
    top: '41px',
    right: '40px'
  },
  closedIcon: {
    ...navIcon,
    borderRadius: '50%',
    width: '50px',
    height: '50px',
    top: '41px',
    right: '40px'
  },
  hl: {
    color: '#44555f',
    fontWeight: '300',
    fontSize: '42px',
    lineHeight: '50px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: '0'
  },
  link: {
    position: 'relative',
    display: 'block',
    maxWidth: '300px',
    width: '100%',
    margin: '0 auto',
    cursor: 'pointer',
    '&:hover': {
      '& img': {
        transform: 'scale(1.1)',
        transition: 'transform 1s'
      },
      '& $search': {
        opacity: 1
      }
    }
  },
  citLink: {
    color: '#606e77',
    textDecoration: 'none',
    '&:hover': {
      color: 'rgb(0, 216, 239)'
    }
  },
  chapLinks: {
    listStyle: 'none',
    '& li': {
      display: 'flex',
      justifyContent: 'flex-start',
      position: 'relative'
    },
    '& a': {
      position: 'relative',
      margin: '5px 10px',
      fontFamily: 'MuseoSans-700',
      color: '#44545F',
      textDecoration: 'none',
      lineHeight: '21px',
      fontSize: '16px',
    },
    '& a:hover': {
      color: 'rgb(0, 216, 239)'
    }
  },
  chapLnk: {
    position: 'absolute',
    top: '5px',
    right: '-13px',
    width: 0,
    height: 0,
    borderTop: '5px solid transparent',
    borderBottom: '5px solid transparent',
    borderLeft: '5px solid #00d8ef',
  },
  txt: {
    '& img': {
      pageBreakInside: 'avoid',
      breakInside: 'avoid',
      pageBreakBefore: 'auto'
    },
    '& tr, td': {
      fontSize: '90%',
      padding: '8px',
      color: '#555',
      backgroundColor: '#f1f1f1',
      border: '1px solid #ddd'
    },
    '& p': {
      '& a': {
        color: '#606e77',
        padding: '4px',
      },
      '& a:hover': {
        backgroundColor: 'rgba(0, 216, 239, 1)',
        color: '#fff',
      }
    }
  },
  info: {
    listStyle: 'none',
    padding: 0,
    margin: 0,
    '& li': {
      margin: '8px 0',
      padding: '8px'
    },
    '& h4': {
      cursor: 'pointer',
      margin: 0,
      display: 'flex',
      justifyContent: 'space-between',
      borderRadius: '20px',
      padding: '8px 16px',
      backgroundColor: 'rgba(0, 216, 239, 1)',
      color: 'rgba(255, 255, 255, 1)',
      transition: 'all 1s',
      overflow: 'hidden',
      '&:hover': {
        backgroundColor: 'rgba(0, 216, 239, 1)',
        color: 'rgba(255, 255, 255, 1)',
      },
      '& img': {
        marginRight: '10px'
      },
    },
    '& div': {
      transition: 'opacity .5s, max-height .5s',
      overflow: 'hidden',
    }
  },
  search: {
    backgroundColor: '#fff',
    zIndex: 1,
    opacity: 0,
    position: 'absolute',
    borderRadius: '50%',
    height: '60px',
    width: '60px',
    top: '50%',
    left: '50%',
    margin: '-30px 0 0 -30px',
    transition: 'opacity .25s',
    '& img': {
      margin: '10px',
      height: '40px',
      width: '40px',
    }
  },
  cit: {
    margin: '20px 0 0 0',
    padding: 0,
    fontSize: '90%'
  },
  more: {
    textAlign: 'center',
    cursor: 'pointer',
    transition: 'all 1s',
    '&:hover': {
      transform: 'scale(1.07)'
    },
  },
  infoBox: {
    opacity: 0,
    animation: '$fadeIn 1s',
    animationFillMode: 'forwards',
    borderRadius: '20px',
    width: '100%',
    minWidth: '260px',
    transition: 'width 1s',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    overflow: 'hidden',
    margin: '50px auto',
    padding: 0,
    '& h3': {
      display: 'flex',
      justifyContent: 'center',
      margin: 0,
      lineHeight: '40px',
      padding: '16px',
      textAlign: 'center',
      backgroundColor: '#5ac3df',
      color: '#fff',
      '& img': {
        marginRight: '8px'
      }
    }
  },
  infoBoxContent: {
    opacity: 0,
    overflow: 'hidden',
    backgroundColor: '#eee',
    padding: '0 8px',
    transition: 'all 1s',
    animation: '$fadeInInfo 1s',
    animationFillMode: 'forwards',
    '& a': {
      backgroundColor: 'rgba(0, 216, 239, .3)',
      color: '#606e77',
      padding: '4px',
      textDecoration: 'none',
    },
    '& a:hover': {
      backgroundColor: 'rgba(0, 216, 239, 1)',
      color: '#fff',
    }
  },
  extra: {
    marginTop: '40px',
    '& p': {
      '& a': {
        color: '#606e77',
        padding: '4px',
      },
      '& a:hover': {
        backgroundColor: 'rgba(0, 216, 239, 1)',
        color: '#fff',
      }
    }
  },
  '@keyframes fadeIn': {
    '0%': { opacity: 0, transform: 'scale(.5)' },
    '50%': { opacity: 1, transform: 'scale(1.07)' },
    '100%': { opacity: 1, transform: 'scale(1)' },
  },
  '@keyframes fadeInInfo': {
    '0%': { opacity: 0 },
    '100%': { opacity: 1 }
  },
  '@media (max-width: 700px)': {
    contentWrapper: {
      '&:after': {
        display: 'none'
      }
    },
    content: {
      padding: '0px 15px'
    },
    cntnWrapper: {
      padding: 0
    },
    hl: {
      fontSize: '30px',
      lineHeight: '40px',
      margin: 0
    },
    openIcon: {
      top: '21px',
      right: '20px'
    },
    closedIcon: {
      top: '21px',
      right: '20px'
    },
    breadcrumb: {
      '& ul': {
        margin: '15px 0 10px'
      }
    }
  }
}
