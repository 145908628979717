export default {
  overview: {
    width: '100%',
    margin: 0,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    '& $videoThumb:first-child': {
      padding: '1% 1% 1% 0'
    },
    '& $videoThumb:last-child': {
      padding: '1% 0 1% 1%'
    }
  },
  playBtn: {
    cursor: 'pointer',
    backgroundColor: 'rgba(255,255,255,1)',
    borderRadius: '50%',
    position: 'absolute',
    top: '50%',
    right: '50%',
    margin: '-25px -25px 0 0',
    width: '50px',
    height: '50px',
    transition: 'transform 1s',
    '&:hover': {
      transition: 'transform 1s',
      transform: 'scale(1.25)'
    }
  },
  play: {
    position: 'absolute',
    top: 0,
    width: '100%',
    height: '100%',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat'
  },
  playerWrapper: {
    margin: '1% 0',
    position: 'relative',
    paddingTop: '56.25%'
  },
  player: {
    cursor: 'pointer',
    position: 'absolute',
    top: 0,
    left: 0
  },
  playPause: {
    position: 'absolute',
    cursor: 'pointer',
    zIndex: 1000,
    top: 0,
    bottom: 0,
    left: 0,
    right: 0
  },
  video: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0
  },
  videoThumb: {
    minWidth: '70px',
    flex: 1,
    padding: '1%'
  },
  citLink: {
    color: '#606e77',
    textDecoration: 'none',
    '&:hover': {
      color: 'rgb(0, 216, 239)'
    }
  },
  cit: {
    margin: '20px 0 0 0',
    padding: 0,
    fontSize: '90%'
  }
}
