import chapter1 from './chapter1';
import chapter2 from './chapter2';
import chapter3 from './chapter3';
import chapter4 from './chapter4';

export const chapterData = [
  {
    id: 'I',
    chapter: 1,
    title: 'Grundlagen',
    titleLong: 'Grundlagen Personalisierte Medizin',
    length: chapter1.length,
    description: 'In diesem Kapitel werden der Begriff und die Vision der Personalisierten Medizin vorgestellt.'
  },
  {
    id: 'II',
    chapter: 2,
    title: 'Personalisierte Medizin in der Praxis',
    length: chapter2.length,
    description: 'Hier bekommen Sie einen Einblick in die Ansätze und Methoden der Personalisierten Medizin.'
  },
  {
    id: 'III',
    chapter: 3,
    title: 'Daten, Daten, Daten',
    length: chapter3.length,
    description: 'Biologische- und Gesundheitsdaten sind das Herzstück der Personalisierten Medizin.'
  },
  {
    id: 'IV',
    chapter: 4,
    title: 'Gesellschaft und Ethik',
    length: chapter3.length,
    description: 'Im Kontext der Personalisierten Medizin werden auch gesellschaftliche und ethische Fragen diskutiert.'
  }
]

const chapterSelect = {
  title: 'Kapitelübersicht',
  chapter: -1,
  type: 'chapterSelect',
}
const chapterEnd = {
  title: 'Vielen Dank für Ihr Interesse!',
  chapter: -1,
  type: 'chapterEnd',
}

export const chapters = [
  ...chapter1,
  chapterSelect,
  ...chapter2,
  chapterSelect,
  ...chapter3,
  chapterSelect,
  ...chapter4,
  chapterSelect,
  chapterEnd
]
