const answer = {
  opacity: 0,
  zIndex: 1,
  width: '100%',
  display: 'flex',
  position: 'relative',
  borderRadius: '20px',
  margin: '4px 16px',
  padding: '16px',
  animation: '$fadeIn 1s',
  animationFillMode: 'forwards',
  cursor: 'pointer',
  border: '4px solid #fff',
  '& img': {
    transition: 'transform .5s'
  },
  '&:hover': {
    border: '4px solid #00d8ef',
    '& img': {
      transform: 'scale(1.5)'
    }
  }
}

export default {
  questionWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    padding: 0
  },
  answer: {
    ...answer,
  	backgroundImage: 'linear-gradient(to right top, #a1a1a1, #a2a2a2, #a2a3a2, #a3a3a3, #a4a4a4)',
    color: '#fff',
  },
  answerTrue: {
    ...answer,
    background: 'rgb(0, 216, 239)',
    border: '4px solid rgb(0, 216, 239)',
    color: '#fff',
    cursor: 'default',
    '&:hover': {
      background: 'rgb(0, 216, 239)',
      color: '#fff',
    }
  },
  answerFalse: {
    ...answer,
    background: '#bbb',
    color: '#fff',
    cursor: 'default',
    border: '4px solid #fff',
    '&:hover': {
      background: '#bbb',
      color: '#fff',
    }
  },
  btn: {
    margin: '16px',
    cursor: 'pointer',
    textDecoration: 'none',
    backgroundColor: '#00d8ef',
    color: '#fff',
    padding: '4px 20px',
    height: '30px',
    lineHeight: '30px',
    borderRadius: '30px',
    '&:hover': {
      backgroundColor: '#44555f',
    }
  },
  info: {
    opacity: 0,
    animation: '$fadeInInfo 1s',
    animationFillMode: 'forwards'
  },
  infoBox: {
    opacity: 0,
    animation: '$fadeIn 1s',
    animationFillMode: 'forwards',
    borderRadius: '20px',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    overflow: 'hidden',
    marginTop: '50px',
    padding: 0,
    '& h3': {
      display: 'flex',
      justifyContent: 'center',
      margin: 0,
      lineHeight: '40px',
      padding: '16px',
      textAlign: 'center',
      backgroundColor: '#5ac3df',
      color: '#fff',
      '& img': {
        marginRight: '8px'
      }
    }
  },
  search: {
    backgroundColor: '#fff',
    zIndex: 1,
    opacity: 0,
    position: 'absolute',
    borderRadius: '50%',
    height: '60px',
    width: '60px',
    top: '50%',
    left: '50%',
    margin: '-30px 0 0 -30px',
    transition: 'opacity .25s',
    '& img': {
      margin: '10px',
      height: '40px',
      width: '40px',
    }
  },
  link: {
    opacity: 0,
    animation: '$fadeIn 1s',
    animationFillMode: 'forwards',
    position: 'relative',
    display: 'block',
    maxWidth: '180px',
    width: '100%',
    margin: '0 auto',
    cursor: 'pointer',
    '&:hover': {
      '& img': {
        transform: 'scale(1.1)',
        transition: 'transform 1s'
      },
      '& $search': {
        opacity: 1
      }
    }
  },
  content: {
    opacity: 0,
    overflow: 'hidden',
    backgroundColor: '#eee',
    padding: '0 8px',
    maxHeight: '40px',
    transition: 'all 1s',
    animation: '$fadeInInfo 1s',
    animationFillMode: 'forwards',
    '& a': {
      backgroundColor: 'rgba(0, 216, 239, .3)',
      color: '#606e77',
      padding: '4px',
      textDecoration: 'none',
    },
    '& a:hover': {
      backgroundColor: 'rgba(0, 216, 239, 1)',
      color: '#fff',
    }
  },
  more: {
    height: '40px',
    lineHeight: '40px',
    textAlign: 'center',
    cursor: 'pointer',
    transition: 'all 1s',
    '&:hover': {
      transform: 'scale(1.07)'
    },
  },
  footer: {
    backgroundColor: '#5ac3df',
    borderRadius: '20px',
    height: '20px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
    color: '#fff'
  },
  '@keyframes fadeIn': {
    '0%': { opacity: 0, transform: 'scale(.5)' },
    '50%': { opacity: 1, transform: 'scale(1.07)' },
    '100%': { opacity: 1, transform: 'scale(1)' },
  },
  '@keyframes fadeInInfo': {
    '0%': { opacity: 0 },
    '100%': { opacity: 1 }
  }
}
