export default {
  wrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    margin: 0,
    padding: 0,
    '& li': {
      overflow: 'hidden',
      padding: '16px',
      margin: '16px 0',
      backgroundColor: '#f6f6f6',
      borderRadius: '25px',
      display: 'flex',
      width: '100%'
    },
    '& img': {
      width: '100%',
      maxWidth: '400px',
      margin: '0 auto',
      padding: '0 58px 0 0'
    }
  },
  number: {
    border: '4px solid #5ac3df',
    display: 'flex',
    fontSize: '130%',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
    borderRadius: '50%',
    backgroundColor: '#fff',
    margin: '20px 20px auto 20px',
    width: '50px',
    minWidth: '50px',
    height: '50px'
  },
  typ: {
    display: 'flex',
    margin: '16px 0 0',
    '& div': {
      display: 'flex',
      flexDirection: 'column',
      color: '#fff',
      backgroundColor: '#48545F',
      textAlign: 'center',
      justifyContent: 'center',
      width: '40px',
      height: '40px',
      borderRadius: '50%',
    }
  },
  '@media (max-width: 700px)': {
    number: {
      fontSize: '100%',
      width: '25px',
      minWidth: '25px',
      height: '25px',
      margin: '5px 5px auto 5px',
    }
  }
}
