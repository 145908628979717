export default {
  header: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
    height: '140px',
    borderBottom: '1px solid #eee',
    '&:after': {
      content: '""',
      position: 'absolute',
      left: 0,
      top: 0,
      bottom: 0,
      width: '10px',
      backgroundColor: '#00d8ef'
    }
  },
  logo: {
    margin: '10px 0 0 39px',
    overflow: 'hidden',
    width: '250px',
  },
  '@media (max-width: 700px)': {
    header: {
      height: '100px',
      '&:after': {
        display: 'none'
      }
    },
    logo: {
      margin: '0 0 0 10px',
      width: '150px',
    }
  }
}
