import React, {useState, useEffect, useRef} from 'react';
import {createUseStyles} from 'react-jss';
import style from './Video.style';
import ReactPlayer from 'react-player';
import imgPlay from 'static/img/play.svg';
import imgLoading from 'static/img/loader.svg';
import 'react-circular-progressbar/dist/styles.css';
import { Slider, Direction } from 'react-player-controls';

const useStyles = createUseStyles(style);

export default ({videoData, onCompleted, page}) => {
  const { url, description } = videoData;
  const classes = useStyles();
  const player = useRef(null);
  const [playVideo, setPlayVideo] = useState(null);
  const [playing, setPlaying] = useState(false);
  const [progress, setProgress] = useState(null);
  const [rerender, setRerender] = useState(0);
  const [lastIntent, setLastIntent] = useState(null);
  const [intent, setIntent] = useState(false);
  const [ready, setReady] = useState(false);
  const delay = ms => {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  const onPlaying = state => {
    setPlaying(state);
  }

  const onEnd = state => {
    setProgress(0);
    setPlaying(false);
    onCompleted(page);
    setIntent(false);
    setLastIntent(null);
    setRerender(rerender + 1);
    setPlayVideo(null);
    delay(1000).then(() => {
      setPlayVideo(url.length === 1 ? url[0] : null);
    })
  }

  const onSeek = value => {
    player.current.seekTo(value, 'fraction');
    setPlaying(true);
    setIntent(false);
  }

  const handleProgress = state => {
    setProgress(state);
  }

  useEffect(() => {
    setPlayVideo(url.length === 1 ? url[0] : null);
    setProgress(null);
    setReady(null);
    setLastIntent(null);
    setIntent(false);
    setPlaying(false);
  },[url,page])


  // A colored bar that will represent the current value
  const SliderBar = ({ direction, value, style }) => (
    <div
      style={Object.assign({}, {
        position: 'absolute',
        background: '#999',
        borderRadius: 8,
      }, direction === Direction.HORIZONTAL ? {
        top: 0,
        bottom: 0,
        left: 0,
        width: `${value * 100}%`,
      } : {
        right: 0,
        bottom: 0,
        left: 0,
        height: `${value * 100}%`,
      }, style)}
    />
  )


  // A composite progress bar component
  const ProgressBar = ({ isEnabled, direction, value, loaded, ...props }) => (
    <Slider
      isEnabled={isEnabled}
      direction={direction}
      onIntentStart={() => setIntent(true)}
      onIntentEnd={() => setIntent(false)}
      onChange={value => onSeek(value)}
      onIntent={intent => setLastIntent(intent)}
      style={{
        width: direction === Direction.HORIZONTAL ? '100%' : 16,
        height: direction === Direction.HORIZONTAL ? 16 : 130,
        borderRadius: 8,
        background: '#eee',
        transition: direction === Direction.HORIZONTAL ? 'width 0.1s' : 'height 0.1s',
        cursor: isEnabled === true ? 'pointer' : 'default',
      }}
      {...props}
    >
      <SliderBar direction={direction} value={value} style={{ background: isEnabled ? '#00d8ef' : '#999' }} />
      <SliderBar direction={direction} value={lastIntent} style={{ background: intent ? 'rgba(0,0,0,.1)' : 'transparent' }} />
    </Slider>
  )

  return (
    <>
      <div className={classes.extra} style={{padding: '16px 0'}}>{description}</div>
      {playVideo && <div className={classes.playerWrapper}>{!ready && <img style={{position: 'absolute', width: '76px', height: '76px', margin: '-38px 0 0 -38px', top: '50%', left: '50%'}} src={imgLoading} alt="" />}
        <div className={classes.play} style={{visibility: ready ? 'visible' : 'hidden'}}>
        {!playing && <div className={classes.playBtn} style={{transform: 'scale(1.5)', zIndex: 100}} onClick={() => onPlaying(!playing)}>
            <img src={imgPlay} style={{position: 'absolute', top: '50%', left: '50%', margin: '-15px 0 0 -15px', width: '30px', height: '30px'}} alt="" />
        </div>}
        <div className={classes.playPause} onClick={() => onPlaying(!playing)} />
        <ReactPlayer
          rerender={rerender}
          light={false}
          url={playVideo}
          onClick={() => setPlaying(false)}
          playing={playing}
          onReady={() => setReady(true)}
          onPlay={() => {setPlaying(true)}}
          onProgress={handleProgress}
          onSeek={() => {}}
          playIcon={<span></span>}
          onEnded={onEnd}
          ref={player}
          progressInterval={200}
          height="100%"
          width="100%"
          youtube={{
            playerVars: { rel: 0, showinfo: 0, ecver: 2 }
          }}
        />
        </div>
      </div>}
      <div style={{margin: '16px 0 0 0'}}>
      {ready ? <div style={{display: 'flex', width: '100%', margin: 0}}>
        <ProgressBar direction={Direction.HORIZONTAL} isEnabled={playing} value={progress && progress.played ? progress.played : 0} loaded={progress && progress.loaded ? progress.loaded : 0} />
      </div> : <div style={{height: '16px'}}></div>}
      </div>
      {url.length > 1 && <div className={classes.overview}>{url.map((lk,idx) => <div key={'vd-'+idx} className={classes.videoThumb} style={{width: 100/url.length-2+'%'}}><div style={lk === playVideo ? {opacity: .5, cursor: 'default'} : {cursor: 'pointer'}} className={classes.playerWrapper}>
            <div className={classes.video} onClick={() => setPlayVideo(lk)}/>
            <ReactPlayer
              className={classes.player}
              url={lk}
              light
              width="100%"
              height="100%"
            />
          </div>
        </div>)}
      </div>}
    </>
  );
};

// <div style={{backgroundColor: 'rgba(255,255,255,.5)'}} onClick={() => onPlaying(!playing)}>
//   {loading && playing && <img src={imgLoading} style={{position: 'absolute', zIndex: 2, width: '50px', height: '50px', opacity: .8}} alt="" />}
//     <img src={light ? imgReplay : playing ? imgPause : imgPlay} style={{position: 'absolute', top: '50%', left: '50%', margin: '-15px 0 0 -15px', width: '30px', height: '30px'}} alt="" />
// </div>
