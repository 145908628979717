import React from 'react';

import imgC1 from 'static/img/c1.png';
import imgC2 from 'static/img/c2.png';
import imgC3 from 'static/img/c3.png';
import imgC4 from 'static/img/c4.png';
import chapter1 from 'constants/chapters/chapter1';
import chapter2 from 'constants/chapters/chapter2';
import chapter3 from 'constants/chapters/chapter3';
import chapter4 from 'constants/chapters/chapter4';

import { CircularProgressbarWithChildren } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import {chapterData} from 'constants/chapters';
import { Link } from 'react-router-dom';
import {CONTENT_ROOT_PATH} from 'constants/paths';

const imgs = [imgC1, imgC2, imgC3, imgC4];
const chapters = [chapter1,chapter2,chapter3,chapter4];

export default ({progress}) => {

    let counter = 0;
    let completed = [];
    let chapterStart = [];
    chapterData.forEach((ch,index) => {
      let done = 0;
      let chapCounter = 0;
      chapterStart[index] = counter + 1;
      chapters[index].forEach((el,idx) => {
        if(progress[counter]) done += 1;
        counter += 1;
        chapCounter += 1;
      });
      counter += 1;
      completed[index] = done === 0 ? 0 : Math.ceil(done / chapCounter *100);
    });

    return <div style={{fontSize: '90%', backgroundImage: 'linear-gradient(to right bottom, #d0d0d0, #d7d7d7, #dfdfdf, #e6e6e6, #eeeeee)', color: '#555', overflow: 'hidden', borderRadius: '16px'}}>
      <div style={{borderBottom: '1px solid #bbb', padding: '16px 0'}}>
        <h1 style={{fontSize: '140%', padding: '16px', overflowWrap: 'break-word', wordWrap: 'break-word', textAlign: 'center', margin: 0, color: '#44555f'}}>Kapitelübersicht</h1>
      </div>
      <ul style={{listStyle: 'none', padding: 0, margin: 0}}>
        {chapterData.map((ch,idx) => <li key={'csel-'+idx} style={{listStyle: 'none'}}>
          <Link to={CONTENT_ROOT_PATH + chapterStart[idx]} className={completed[idx] > 75 ? 'selectChapterDisabled' : 'selectChapter'}>
            <div style={{display: 'flex', position: 'relative', flexDirection: 'column', justifyContent: 'flex-start'}}>
              <div style={{marginRight: '16px', width: '100px', minWidth: '100px'}}>
                <CircularProgressbarWithChildren
                  styles={{
                    path: {
                      stroke: '#44555f',
                      strokeLinecap: 'round',
                    },
                    trail: {
                      stroke: '#eee',
                    },
                  }}
                  value={completed[idx]}>
                  <img style={{width: '50%'}} src={imgs[idx]} alt="" />
                  <div style={{position: 'absolute', bottom: '-20px', fontSize: '75%', left: 0, width: '100%', textAlign: 'center'}} >{completed[idx]}%</div>
                </CircularProgressbarWithChildren>
              </div>
            </div>
            <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
              <h4 style={{display: 'flex', margin: 0,  justifyContent: 'center', flexDirection: 'column'}}>{ch.longTitle ? ch.titleLong : ch.title}</h4>
              <span>{ch.description}</span>
            </div>
          </Link>
        </li>)}
      </ul>
    </div>
  }
