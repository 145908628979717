import React from 'react';
import { useDrag } from 'react-dnd';
import {createUseStyles} from 'react-jss';
import style from './Quiz.style';

const useStyles = createUseStyles(style);

export default ({ text, id }) => {
  const item = { text, id: id, type: 'box' };
  const classes = useStyles();
  const [{ opacity }, drag] = useDrag({
    item,
    end(item, monitor) {
      console.log(item,monitor)
      const dropResult = monitor.getDropResult()
      if (item && dropResult) {
        console.log(item,dropResult)
        // let alertMessage = ''
        // const isDropAllowed =
        //   dropResult.allowedDropEffect === 'any' ||
        //   dropResult.allowedDropEffect === dropResult.dropEffect
        // if (isDropAllowed) {
        //   const isCopyAction = dropResult.dropEffect === 'copy'
        //   const actionName = isCopyAction ? 'copied' : 'moved'
        //   alertMessage = `You ${actionName} ${item.name} into ${dropResult.name}!`
        // } else {
        //   alertMessage = `You cannot ${dropResult.dropEffect} an item into the ${dropResult.name}`
        // }
      }
    },
    collect: (monitor) => ({
      opacity: monitor.isDragging() ? 0.8 : 1,
    }),
  })
  return (
    <div ref={drag} className={classes.object} style={{ opacity }}>
      {text}
    </div>
  )
}
