import React, {useCallback, useState, useRef, useEffect} from 'react';
import {createUseStyles} from 'react-jss';
import { Link, useParams, useHistory } from 'react-router-dom';
import { HOME_PATH, CONTENT_ROOT_PATH } from 'constants/paths';
import Video from 'components/Video';
import Discussion from 'components/Discussion';
import Questions from 'components/Questions';
import Newspaper from 'components/Newspaper';
import DnaArmband from 'components/DnaArmband';
import Roleplay from 'components/Roleplay';
import Textgap from 'components/Textgap';
import {chapters, chapterData} from 'constants/chapters';
import style from './Content.style';
import Menu from 'components/Menu';
import Header from 'components/Header';
import Footer from 'components/Footer';
import Error404 from 'routes/Error404';
import citation from 'constants/citation';
import info from 'static/img/info.svg';
import imgPlus from 'static/img/plus-box.svg';
import imgMinus from 'static/img/minus-box.svg';
import imgMenuOpen from 'static/img/menu-unfold.svg';
import imgEmail from 'static/img/email.svg';
import imgMenuClosed from 'static/img/menu-fold.svg';
import ChapterSelect from 'components/ChapterSelect';

const useStyles = createUseStyles(style);
const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return { width, height };
}
const delay = ms => {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export default () => {
  const classes = useStyles();
  const currentPageId = useRef();
  let { page=1 } = useParams();

  if (!Number.isInteger(Number(page)) || page > chapters.length || page <= 0) {
    return <Error404 />
  } else page = Number(page);

  const [exp, setExp] = useState(null);
  const [expInfo, setExpInfo] = useState(false);
  const [completed, setCompleted] = useState(JSON.parse(localStorage.getItem('progress')) || {});
  const [isMobile, setIsMobile] = useState(getWindowDimensions().width <= 700 ? true : false);
  const [open, setOpen] = useState(JSON.parse(localStorage.getItem('nv')) === false || (JSON.parse(localStorage.getItem('nv')) !== true && isMobile) ? false : true);
  const setNv = open => {
    setOpen(open);
    localStorage.setItem('nv', JSON.stringify(open));
  }

  useEffect(() => {
    try {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    } catch (error) {
      window.scrollTo(0, 0);
    }
  }, [page]);

  const nvClick = () => {
    if(isMobile) setNv(false);
  }

  const history = useHistory();
  const currentElement = chapters[page-1];
  const currentChapterNum = currentElement.chapter;
  const currentChapter = chapterData[currentChapterNum-1] || null;


  const next = page >= chapters.length ? null : CONTENT_ROOT_PATH+(Number(page)+1);
  const back = page === 1 ? null : CONTENT_ROOT_PATH+(page-1);

  useEffect(() => {
    function handleResize() {
      let newWidth = getWindowDimensions().width;
      if(newWidth <= 700) {
        setIsMobile(true);
      } else setIsMobile(false);
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    currentPageId.current = page
    if(chapters[page-1].type !== 'questions' && chapters[page-1].type !== 'video' && chapters[page-1].type !== 'textgap')
      delay(5000).then(() => {
        if(page === currentPageId.current){
          let compl = JSON.parse(localStorage.getItem('progress'));
          let newCompleted = {...compl, [page-1]: true};
          setCompleted(newCompleted);
          localStorage.setItem("progress", JSON.stringify(newCompleted));
        }
    })
  },[page]);

  const onCompleted = page => {
    let compl = JSON.parse(localStorage.getItem('progress'));
    let newCompleted = {...compl, [page-1]: true};
    localStorage.setItem("progress", JSON.stringify(newCompleted));
    setCompleted(newCompleted);
  }

  const resetCompleted = () => {
    localStorage.setItem("progress", JSON.stringify({}));
    setCompleted({});
  }

  let citationInfo = [];

  const getCitationRef = (citIdx) => {
    let cit = citation[citIdx];
    switch(cit.type){
      case 'url':
        if(cit.url.length > 1){
          citationInfo.push({id: citIdx, content: <div key={'cit-lnk-'+citIdx}><ul>
            {cit.url.map((url,idx) => <li key={'cit-lnk-'+citIdx+'-'+idx}><a className={classes.citLink} target="_blank" rel="noopener noreferrer" href={url.link} alt={url.title}>{url.title ? url.title : url.link}</a></li>)}
          </ul></div>})
        } else {
          let url = cit.url[0];
          citationInfo.push({id: citIdx, content: <div key={'cit-lnk-'+citIdx}><a className={classes.citLink} target="_blank" rel="noopener noreferrer" href={url.link} alt={url.title}>{url.title ? url.title : url.link}</a></div>});
        }

        break;
      default:
        citationInfo.push({id: citIdx, content: <div key={'cit-txt-'+citIdx}>
          {cit.text}
        </div>})
    }
    return <i>[{citIdx}]</i>;
  }

  if (currentElement.cit && currentElement.cit.length > 0) {
    currentElement.cit.forEach(c => getCitationRef(c));
  }


  useEffect(() => {
    document.title = currentElement.title;
  },[currentElement.title])

  const keyFunction = useCallback((event) => {
    if(event.keyCode === 39 || event.keyCode === 37) {
      if (event.keyCode === 39 && next)
        history.push(next);
      if(event.keyCode === 37 && back)
        history.push(back);
    }
  }, [back, history, next]);

  useEffect(() => {
    console.log(process.env.NODE_ENV)
    if (process.env.NODE_ENV !== 'development'){
      document.addEventListener("contextmenu", (event) => {
        event.preventDefault();
      });
    }
    document.addEventListener("keydown", keyFunction, false);
    return () => {
      document.removeEventListener("keydown", keyFunction, false);
    };
  },[keyFunction]);

  useEffect(() => {
    setExpInfo(false);
  }, [page])

  let show;

  switch (currentElement.type) {
    case 'preview':
      show = <div style={{margin: '0'}}>{currentElement.content}</div>
      break;
    case 'list':
      show = <ul>
              {currentElement.content.map((txt,idx) => {
                return <li key={'lst-'+idx}>{txt}</li>
              })}
              </ul>
      break;
    case 'video':
      show = <Video page={page} onCompleted={onCompleted} videoData={currentElement} />
      break;
    case 'chapterSelect':
      show = <ChapterSelect progress={completed}/>
      break;
    case 'chapterEnd':
      show = <div style={{textAlign: 'center', margin: '100px 0'}}>
          <p>Das ist das Ende des Onlinekurses "Personalisierte Medizin".</p>
          <p style={{marginTop: '50px'}}>Empfehlen Sie den Onlinekurs gerne weiter<br />oder schicken Sie Ihr Feedback an die ÖPMM.</p>
          <p style={{margin: 0, display: 'flex', justifyContent: 'center'}}><a href="https://www.personalized-medicine.at/kontakt/"><img style={{width: '100px', height: '100px'}} src={imgEmail} alt="" /></a></p>
        </div>
      break;
    case 'dnaArmband':
      show = <DnaArmband />
      break;
    case 'roleplay':
      show = <Roleplay roleplayData={currentElement} />
      break;
    case 'discussion':
      show = <Discussion page={page} discussionData={currentElement} />
      break;
    case 'questions':
      show = <Questions questionsData={currentElement} page={page} onCompleted={onCompleted}/>
      break;
    case 'newspaper':
      show = <Newspaper newspaperData={currentElement} />
      break;
    case 'textgap':
      show = <Textgap page={page} onCompleted={onCompleted}/>
      break;
    default:
      show = <div className={classes.txt}>{currentElement.content}</div>
  }
  const infoExp = info => {
    return <ul className={classes.info}>
      {info.map((nfo,idx) => <li key={'nfo-'+idx}>
        <h4 onClick={() => setExp(idx + 1 === exp ? null : idx+1)}>{nfo.title} <img src={idx + 1 === exp ? imgMinus : imgPlus} alt="" /></h4>
        <div style={exp === idx+1 ? {maxHeight: '2000px', opacity: 1} : {maxHeight: '0px', opacity: 0}}><div style={{padding: '8px'}}>{nfo.content}</div></div>
      </li>)}
    </ul>
  }

  return <>
    <Header />
    {open ? <div className={classes.closedIcon} onClick={() => setNv(false)}><img src={imgMenuOpen} alt="Menü schließen"/></div> :
      <div className={classes.openIcon} style={{backgroundColor: '#00d8ef'}} onClick={() => setNv(true)} ><img src={imgMenuClosed} alt="Menü öffnen"/></div>}
    <main className={classes.contentWrapper}>
      {!(open && isMobile) && <div className={classes.content}>
        <nav className={classes.breadcrumb}>
          <ul>
            <li><a href="https://www.personalized-medicine.at/" title="Home">Home</a></li>
            <li><Link to={HOME_PATH} title="Home">Onlinekurs</Link></li>
            <li>{currentChapter ? currentChapter.title : currentElement.title}</li>
          </ul>
        </nav>
        <div className={classes.cntnWrapper}>
          {currentElement.type !== 'preview' && <div className={classes.header}>{currentElement.icon && <div className={classes.icon}><img src={currentElement.icon} alt="" /></div>}
            {currentElement.type !== 'chapterSelect' && <h2 className={classes.hl}>{currentElement.title}</h2>}
          </div>}
          {(!currentElement.infoBox || (currentElement.infoBox && !expInfo)) && show}
          {currentElement.infoBox && <div className={classes.infoBox} style={{animationDelay: '3s'}}>
          <h3 style={{cursor: 'pointer'}} onClick={() => {setExpInfo(!expInfo)}} ><img style={{width: '40px', height: '40px'}} src={info} alt=""/>{currentElement.infoBox.title}</h3>
          {expInfo && <div className={classes.infoBoxContent} style={{opacity: 1}}>
            {currentElement.infoBox.content}
            {currentElement.infoBox.info && infoExp(currentElement.infoBox.info)}
          </div>}
          </div>}
          {currentElement.info && infoExp(currentElement.info)}
          {currentElement.extra && <div className={classes.extra}>{currentElement.extra}</div>}
          {currentElement.chapterLinks && <>
            <h4 style={{marginTop: '50px'}}>{currentElement.linkstitle ? currentElement.linkstitle : 'Mehr zum Thema finden Sie in diesen Kapiteln:'}</h4>
            <ul className={classes.chapLinks}>
          {currentElement.chapterLinks.map((link, idx) => <li key={'cap-lnk-'+idx}><img style={{maxWidth: '24px', maxHeight: '24px'}} src={link.icon} alt="" />{link.title.map((lnkInfo, index) => <Link key={'cap-lnk-'+idx+'-'+index} to={'/seite/' + lnkInfo.page}>{lnkInfo.title} {index + 1 < link.title.length && <span className={classes.chapLnk}></span>}</Link>)}</li>)}
            </ul>
          </>}
          {citationInfo.length > 0 &&
            <>
              <h4 style={{marginTop: '50px'}}>{currentElement.cittitle ? currentElement.cittitle : 'Lesen Sie hier mehr zum Thema:'}</h4>
              <ul className={classes.cit}>
                {citationInfo.map((cit,idx) => <li key={'cit-'+cit.id} style={{display: 'flex'}}>[{cit.id}]&nbsp;{cit.content}</li>)}
              </ul>
            </>}
          <div className={classes.btnWrapper}>
            {page===1 && <Link className={classes.btn} to={'/'}>Startseite</Link>}
            {back && !(open && isMobile) ? <Link className={classes.btn} to={back}>Zurück</Link> : <span />}
            {next && !(open && isMobile) ? <Link className={classes.btn} to={next}>Weiter</Link> : <span />}
          </div>
          <div style={{margin: '16px 0 50px 0', display: 'flex', justifyContent: 'center'}}><strong style={{fontSize: '14px', color: 'rgb(161, 169, 175)'}}>{page} / {chapters.length}</strong></div>
        </div>
        <div style={{flex:1}} />
      </div>}
      {open && <Menu nvClick={nvClick} setNv={setNv} completed={completed} resetCompleted={resetCompleted}/>}
    </main>
    <Footer />
  </>

};
