import {
  HOME_PATH,
  CONTENT_PATH,
  IMPRESSUM_PATH
} from 'constants/paths';

import Content from 'routes/Content';
import Info from 'routes/Info';


export default [
  { id: 'impressum', path: IMPRESSUM_PATH, component: Info },
  { id: 'content', path: HOME_PATH, component: Info },
  { id: 'content', path: CONTENT_PATH, component: Content }
];
