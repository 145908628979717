export default {
  1: {
    type: 'url',
    title: 'Quelle: Homepage ÖPPM',
    url: [
      {link: 'https://www.personalized-medicine.at/personalisierte-medizin/was-ist-personalisierte-medizin/', title: 'personalized-medicine.at'}
    ],
    author: 'ÖPPM',
    date: '20.40.2020'
  },
  2: {
    type: 'text',
    title: 'Quelle: Homepage ÖPPM',
    text: 'Spear BB et al. (2001): Clinical application of pharmacogenetics. In: TRENDS in Molecular Medicine Vol.7 No.5 (201-204)'
  },
  3: {
    type: 'text',
    text: 'Ogura, Y et al. (2001): A frameshift mutation in NOD2 associated with susceptibility to Crohn’s disease. In: Nature 411: 603–606'
  },
  4: {
    type: 'text',
    title: 'Quelle: Homepage ÖPPM',
    text: 'Swagerty, D.L. et al. (2002) Lactose intolerance. In: Am Fam Physician 65(9):1845-50.'
  },
  5: {
    type: 'url',
    title: 'Weitere Informationen zu PCR',
    url: [
      {title: 'gesundheit.gv.at', link: 'https://www.gesundheit.gv.at/labor/laborbefund/polymerase-ketten-reaktion'},
      {title: 'openscience.or.at', link: 'https://www.openscience.or.at/de/wissen/genetik-und-zellbiologie/2020-04-22-die-polymerase-kettenreaktion-pcr/'}
    ],
  },
  6: {
    type: 'url',
    title: 'Mehr Info zu Biobanken',
    url: [
      {title: 'openscience.or.at', link: 'https://www.openscience.or.at/de/wissen/medizin-mensch-ernaehrung/2013-03-10-biobanken/'},
      {link: 'https://www.personalized-medicine.at/personalisierte-medizin/was-ist-personalisierte-medizin/', title: 'personalized-medicine.at'}
    ]
  },
  7: {
    type: 'url',
    url: [
      {link: 'https://www.ema.europa.eu/en/medicines', title: 'ema.europa.eu'}
    ]
  },
  8: {
    type: 'text',
    text: 'Ma, Lee, Kuo (2010). HLA-B*5701 testing to predict abacavir hypersensitivity. In: PLOS Currents Evidence on Genomic Tests. Edition 1. DOI: 10.1371/currents.RRN1203.'
  },
  9: {
    type: 'text',
    text: 'Stintzing, S; Heinemann, V; Jung, A; Moosmann, N; Hiddemann, W; Kirchner, T (2009): The treatment of colorectal carcinoma with monoclonal antibodies. The importance of KRAS mutation analysis and EGFR status. In: Deutsches Arzteblatt international 106 (12), S. 202–206. DOI: 10.3238/arztebl.2009.0202.'
  },
  10: {
    type: 'text',
    text: 'Becquemont, Laurent (2017): Type 1 Gaucher disease (CYP2D6-eliglustat). In: Therapie 72 (2), S. 323–326. DOI: 10.1016/j.therap.2016.09.019.'
  },
  11: {
    type: 'text',
    text: 'Manolio, Teri A. (2010): Genomewide association studies and assessment of the risk of disease. In: The New England journal of medicine 363 (2), S. 166–176. DOI: 10.1056/NEJMra0905980.'
  },
  12: {
    type: 'url',
    url: [{link: 'https://eur-lex.europa.eu/legal-content/DE/TXT/PDF/?uri=CELEX:32016R0679', title: 'europa.eu'}]
  },
  13: {
    type: 'url',
    url: [{link: 'https://www.zwt-graz.at/mieter/biobank/', title: 'zwt-graz.at'}]
  },
  14: {
    type: 'url',
    url: [{link: 'http://bbmri.at/about-biobanks', title: 'bbmri.at'}]
  },
  15: {
    type: 'url',
    url: [{link: 'http://bbmri.at/biobanken', title: 'bbmri.at'}]
  },
  16: {
    type: 'url',
    url: [{link: 'https://oepgk.at/studie-chancen-und-risiken-von-gesundheits-apps/', title: 'oepgk.at'}]
  },
  17: {
    type: 'text',
    text: 'Burger et al. Absence of the Lactase-Persistence associated allele in early Neolithic Europeans. Proceedings of the Natio-nal Academy of Sciences, 104, 3736-3741 (2007).'
  }
}
