import React from 'react';
import { Link } from 'react-router-dom';
import Header from 'components/Header';
import Footer from 'components/Footer';
import {createUseStyles} from 'react-jss';
import style from './Content/Content.style';

const useStyles = createUseStyles(style);

export default () => {
  const classes = useStyles();
  return <>
    <Header />
    <main style={{flex:1, display: 'flex', flexDirection: 'column', justifyContent: 'center', textAlign: 'center'}}>
      <div>Seite nicht gefunden.</div><div style={{maxWidth: '300px', width: '100%', margin: '0 auto', marginTop: '36px'}}><Link className={classes.btn} to={'/'}>Zur Startseite</Link></div>
    </main>
    <Footer />
  </>;
};
