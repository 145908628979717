export default {
  newspaper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    flexWrap: 'wrap',
    '& h1':{
      width: '100%',
      borderBottom: '4px solid #eee'
    },
    '& $article': {
      transition: 'all .4s'
    },
    '& $article:nth-child(1)': {
      transform: 'rotate(-4deg)'
    },
    '& $article:nth-child(2)': {
      transform: 'rotate(2deg)'
    },
    '& $article:nth-child(3)': {
      transform: 'rotate(3deg)'
    },
    '& $article:hover': {
      transform: 'rotate(0) scale(1.1)'
    }
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    '& span': {
      fontSize: '70%',
      textAlign: 'right'
    }
  },
  search: {
    backgroundColor: '#fff',
    zIndex: 1,
    opacity: 0,
    position: 'absolute',
    borderRadius: '50%',
    height: '60px',
    width: '60px',
    top: '50%',
    left: '50%',
    margin: '-30px 0 0 -30px',
    transition: 'opacity .25s',
    '& img': {
      margin: '10px',
      height: '40px',
      width: '40px',
    }
  },
  article: {
    overflow: 'hidden',
    backgroundColor: '#fff',
    boxShadow: '2px 2px 15px 0 #e5e5e5',
    cursor: 'pointer',
    position: 'relative',
    padding: '8px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    flex: 1,
    margin: '20px',
    minWidth: '250px',
    maxWidth: '350px',
    color: '#555',
    textDecoration: 'none',
  	'& h2': {
      textAlign: 'center'
    },
    '&:hover': {
      transform: 'scale(1.1) rotate(0)',
      '& $search': {
        opacity: 1
      }
    }
  },
  content: {
    position: 'relative',
    '& span': {
      display: 'block',
      height: '15px',
      margin: '3px 0',
      backgroundColor: '#eee'
    },
    '& span:last-child': {
      width: '30%'
    }
  },
  footer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    fontSize: '70%',
    '& span': {
      color: '#bbb'
    },
    '& span:first-child': {

    },
    '& span:last-child': {
      textAlign: 'right'
    }

  }
}
