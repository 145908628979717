import React, {useEffect, useCallback, useState} from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import {createUseStyles} from 'react-jss';
import style from './Info.style';
import imgPlus from 'static/img/plus-box.svg';
import imgMinus from 'static/img/minus-box.svg';
import Header from 'components/Header';
import Footer from 'components/Footer';
import iconDiscuss from 'static/img/forum.svg';
import iconThink from 'static/img/thought-bubble.svg';
import iconVideo from 'static/img/video.svg';
import iconSearch from 'static/img/read.svg';
import Tooltip from 'components/Tooltip';
import iconDna from 'static/img/dna.svg';
import iconPdf from 'static/img/pdf.svg';
import iconNav from 'static/img/navigate.svg';
import iconLnk from 'static/img/open-in-new.svg';
import imgBMBWF from 'static/img/BMBWF_Logo.png';
import imgDform from 'static/img/dform_logo.png';
import imgZimtfilm from 'static/img/zimtfilm_logo.png';

import { IMPRESSUM_PATH, HOME_PATH, CONTENT_ROOT_PATH } from 'constants/paths';
const useStyles = createUseStyles(style);

const icons = [
  {icon: iconDiscuss, title: 'Diskussionen'},
  {icon: iconThink, title: 'Denkaufgaben'},
  {icon: iconVideo, title: 'Videos'},
  {icon: iconSearch, title: 'Weiterführende Informationen'},
  {icon: iconDna, title: 'Quizze'}
]

export default () => {
  const classes = useStyles();
  const location = useLocation();
  const path = location.pathname;
  const history = useHistory();
  const [exp, setExp] = useState(null);

  const infoExp = info => {
    return <ul className={classes.info}>
      {info.map((nfo,idx) => <li key={'nfo-'+idx}>
        <h4 onClick={() => setExp(idx + 1 === exp ? null : idx+1)}>{nfo.title} <img src={idx + 1 === exp ? imgMinus : imgPlus} alt="" /></h4>
        <div style={exp === idx+1 ? {maxHeight: '2000px', opacity: 1} : {maxHeight: '0px', opacity: 0}}>{nfo.content}</div>
      </li>)}
    </ul>
  }

  const keyFunction = useCallback((event) => {
    if (event.keyCode === 39 && path !== IMPRESSUM_PATH)
      history.push(CONTENT_ROOT_PATH + '1');
  }, [history, path]);

  useEffect(() => {
    if (process.env.NODE_ENV !== 'development'){
      document.addEventListener("contextmenu", (event) => {
        event.preventDefault();
      });
    }
    document.addEventListener("keydown", keyFunction, false);
    return () => {
      document.removeEventListener("keydown", keyFunction, false);
    };

  },[keyFunction]);


  return (<>
    <Header />
    <div className={classes.contentWrapper}>
      {path === IMPRESSUM_PATH ? <div className={classes.content}>
        <nav className={classes.breadcrumb}>
          <ul>
            <li><a href="https://www.personalized-medicine.at/" title="Home">Home</a></li>
            <li><Link to={HOME_PATH} title="Home">Onlinekurs</Link></li>
            <li>Impressum</li>
          </ul>
        </nav>
        <h1>Impressum</h1>
        <p>
          Dieser Kurs wurde vom Verein Open Science, Lebenswissenschaften im Dialog in Kooperation mit der Österreichischen Plattform für Personalisierte Medizin erstellt.
        </p>
        <p>
          Open Science - Lebenswissenschaften im Dialog<br />
          MQM 3.4, Maria Jacobi Gasse 1, 1030 Wien<br />
          <a href="mailto:office@personalized-medicine.at">office@openscience.or.at</a>
        </p>
        <p>
          Österreichische Plattform für Personalisierte Medizin<br />
          c/o Medizinische Universität Wien<br />
          Klinisches Institut für Pathologie<br />
          Währinger Gürtel 18-20, 1090 Wien<br />
          <a href="mailto:office@personalized-medicine.at">office@personalized-medicine.at</a>
        </p>
        <ul className={classes.logos}>
          <li><a target="_blank" rel="noopener noreferrer" href="https://www.bmbwf.gv.at/"><img src={imgBMBWF} alt="BMBWF" /></a><span>gefördert aus den Mitteln des <a target="_blank" rel="noopener noreferrer" href="https://www.bmbwf.gv.at/">Bundesministerium Bildung, Wissenschaft und Forschung</a></span></li>
          <li><a target="_blank" rel="noopener noreferrer" href="https://zimtfilm.com"><img src={imgZimtfilm} alt="zimtfilm" /></a> <span>Videos, technische Umsetzung und Programmierung: <a target="_blank" rel="noopener noreferrer" href="https://zimtfilm.com">zimtfilm e.U.</a></span></li>
          <li><a target="_blank" rel="noopener noreferrer" href="http://www.dform.at/"><img src={imgDform} alt="dform" /></a><span>Grafiken von <a target="_blank" rel="noopener noreferrer" href="http://www.dform.at/">dform</a> </span></li>
        </ul>
      </div> :
      <div className={classes.content}>
        <nav className={classes.breadcrumb}>
          <ul>
            <li><a href="https://www.personalized-medicine.at/" title="Home">Home</a></li>
            <li>Onlinekurs</li>
          </ul>
        </nav>
        <Tooltip />
        <div style={{maxWidth: '480px', margin: '24px auto 0'}}>
          <h2 style={{margin: 0, fontSize: '90%', textAlign: 'center'}}>Onlinekurs:</h2>
          <h1 style={{margin: 0, textAlign: 'center'}}>Personalisierte Medizin</h1>
          <p style={{textAlign: 'center'}}>Sie interessieren sich für Personalisierte Medizin?<br />Herzlich willkommen beim Onlinekurs!</p>
          <div className={classes.icons}>{icons.map((icon,idx) => <div key={'sIcon-'+idx} style={{padding: 0}} className="tooltip" data-tip={icon.title}><img className="icon" key={'intro-ico-'+idx} src={icon.icon} alt="" /></div>)}</div>
          <p style={{textAlign: 'center'}}>Für diesen Kurs ist kein Vorwissen nötig. </p>
          <div style={{textAlign: 'center', height: '80px', margin: '40px auto 0', width: '100%'}}>
            <Link className={classes.btn} to={CONTENT_ROOT_PATH+'1'}>START</Link>
          </div>
          <div style={{margin: 0, color: 'eee', textAlign: 'center', fontSize: '14px', lineHeight: '20px'}}>Zur Navigation können auch die Pfeiltasten <img style={{height: '15px', transform: 'rotate(180deg)'}} src={iconNav} alt="zurück" /> <img style={{height: '15px'}} src={iconNav} alt="weiter" /> verwendet werden.</div>
          <div style={{margin: '32px 0', borderRadius: '20px'}}>{infoExp([{
            title: 'Das erwartet Sie',
            content: <div style={{padding: '8px', fontSize: '90%'}}>
              <p>Der Onlinekurs Personalisierte Medizin vermittelt Grundlagen der Personalisierten Medizin, Anwendungsbeispiele und spricht auch ethische und gesellschaftliche Aspekte an. </p>
              <p>Die Inhalte werden anhand von Infotexten und Videos erklärt und mit Diskussionsanregungen und Aufgaben aufgelockert.</p>
              <p>Das erste Kapitel des Kurses vermittelt die Grundlagen der Personalisierten Medizin und ist aufbauend gestaltet. Die weiteren Kapitel können je nach Interesse frei ausgewählt und in verschiedener Reihenfolge absolviert werden.</p>
              <p>Text- und Videoinhalte des Kurses nehmen insgesamt ungefähr zwei Stunden in Anspruch. Für die Diskussionsanregungen und das Rollenspiel sollte jedoch extra Zeit eingeplant werden. Die Zeit kann frei eingeteilt werden und der Kurs jederzeit unterbrochen und wieder aufgenommen werden. </p>
              <p><strong>Info für Privatpersonen:</strong></p>
              <p>Wenn Sie den Kurs gemeinsam mit anderen Personen starten, können Sie die entsprechenden Diskussionsaufgaben auch in der Gruppe behandeln. Sie können diese Anregungen auch nutzen, um das Thema mit Freund*innen oder in der Familie zu besprechen. </p>
              <p><strong>Info für Lehrpersonen:</strong></p>
              <p>Der Kurs eignet sich besonders für die Einbettung in den Biologieunterricht in der 8. Stufe AHS sowie in den Ethikunterricht der Oberstufe.</p>
              <p>Das Grundlagenkapitel und das Kapitel „Personalisierte Medizin in der Praxis“ behandeln die Themengebiete Biomedizin und Genetik. Diese Inhalte eignen sich besonders für das vertiefende Wahlpflichtfach Biologie in der Oberstufe.</p>
              <p>Die Kapitel „Daten, Daten, Daten“ sowie „Gesellschaft und Ethik“ sprechen Datenschutz, gesellschaftliche und ethische Aspekte der Personalisierten Medizin an.</p>
              <p>Für die Nutzung im Unterricht finden Sie weitere Unterlagen im „Lehrpaket“</p>
            </div>
          },{
            title: 'Lehrmaterialien',
            content: <div style={{padding: '8px', fontSize: '90%'}}>
              <p> Wenn der Kurs im Rahmen des Unterrichtes absolviert wird, sollte die Lehrperson die für den Unterricht in Frage kommenden Kapitel im Vorhinein auswählen und die Diskussionsrunden vorbereiten.</p>
              <p>Jedes Kapitel deckt inklusive Diskussionen und interaktiven Aufgaben ungefähr eine Unterrichtseinheit ab.</p>
              <p>Für die Nutzung im Unterricht finden Sie hier Unterlagen zum Download:</p>
              <p><strong>Downloads</strong></p>
              <ul className={classes.downloads}>
                <li><a target="_blank" rel="noopener noreferrer" href="/pdf/Onlinekurs.pdf"><img src={iconPdf} alt="" />Onlinekurs</a></li>
                <li><a target="_blank" rel="noopener noreferrer" href="/pdf/Rollenspiel.pdf"><img src={iconPdf} alt="" />Rollenspiel</a></li>
                <li><a target="_blank" rel="noopener noreferrer" href="/pdf/Beipackzettel-Ibuprofen.pdf"><img src={iconPdf} alt="" />Beipackzettel</a></li>
                <li><a target="_blank" rel="noopener noreferrer" href="/pdf/DNArmband.pdf"><img src={iconPdf} alt="" />DNArmband</a></li>
                <li><a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/playlist?list=PLAfksbqUmu2Z0LZVqFG4bpO2dqZxrKXL9"><img src={iconLnk} alt="" />YouTube Playlist Personalisierte Medizin</a></li>
              </ul>
            </div>
          }])}
          
          </div>
        </div>
      </div>}
    </div>
    <Footer />
  </>);
  // <p>Sie werden über die Grundlagen der Personalisierten Medizin lernen, in Videos Einblicke in die vielfältigen Arbeitsbereiche bekommen und zur Diskussion zu ethischen und gesellschaftlichen Aspekten der Personalisierten Medizin angeregt.</p>

};
