import React, {useState} from 'react';
import {createUseStyles} from 'react-jss';
import imgDiscuss1 from 'static/img/discuss1.png';
import imgDiscuss2 from 'static/img/discuss2.png';
import imgDiscuss3 from 'static/img/discuss3.png';
import imgDiscuss4 from 'static/img/discuss4.png';
import imgCheck from 'static/img/checkbox-marked.svg';
import imgWrong from 'static/img/close-box.svg';
import imgQuestion from 'static/img/question.svg';
import style from './Questions.style';

let imgDiscuss = [
  imgDiscuss1,
  imgDiscuss2,
  imgDiscuss3,
  imgDiscuss4
]
const shuffle = array => {
  var currentIndex = array.length, temporaryValue, randomIndex;
  while (0 !== currentIndex) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }
  return array;
}

const useStyles = createUseStyles(style);

export default ({questionsData, onCompleted, show, page}) => {

  const { description, questions } = questionsData;
  const classes = useStyles();
  const [showRes, setShowRes] = useState(show ? true : false);
  const [selected, setSelected] = useState(false);

  imgDiscuss = shuffle(imgDiscuss);
  return (
    <>
      {description && description}
      {questions.map((q, idx) => <div key={'q-'+idx} className={classes.questionWrapper} style={idx%2 ? {} : {flexDirection: 'row-reverse'}}>
        <div onClick={() => {
          if(showRes === false){
            let newSelected = {...selected}
            newSelected[idx] = newSelected[idx] ? false : true;
            setSelected(newSelected);
          }
        }} className={showRes ? (q.isTrue ? classes.answerTrue : classes.answerFalse) : classes.answer} style={selected[idx] && !showRes ? {animationDelay: idx*.7+.3+'s', border: '4px solid #00d8ef'} : {}}>
          {true && <span style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}><img style={{marginRight: '16px', minWidth: '40px', width: '40px', height: '40px'}} src={showRes ? (q.isTrue ? imgCheck : imgWrong) : imgQuestion} alt=""/></span>}
          <span style={{display: 'flex', justifyContent: 'center', flexDirection: 'column'}}>{q.question}</span>
        </div>
      </div>)}
      <div style={{display: 'flex', justifyContent: 'center'}}>
        {showRes === false && <span onClick={() => {
          if (Object.keys(selected).length > 0){
            setShowRes(true);
            onCompleted(page);
          }
        }} className={classes.btn} style={(Object.keys(selected).length === 0) ? {padding: '8px 16px', backgroundColor: '#eee', cursor: 'default'} : {padding: '8px 16px'}}>Auflösen</span>}
      </div>
    </>

  );
};
