export default {
  nav: {
    position: 'relative',
    minWidth: '320px',
    width: '320px',
    backgroundColor: '#f6f6f6',
    backgroundImage: 'linear-gradient(to right top, #f6f6f6, #efefef, #e8e8e8, #e2e2e2, #dbdbdb)',
    fontSize: '90%',
    margin: 0,
    '& h2': {
      color: '#a1a9af',
      margin: '10px 0 0',
      padding: '8px',
      fontSize: '110%',
      fontFamily: 'MuseoSans-700',
      fontWeight: 700
    },
    '& ul': {
      margin: 0,
      padding: 0,
      listStyle: 'none'
    },
    '& li': {
      opacity: 0,
      animation: '$fadeInInfo 1s',
      animationFillMode: 'forwards',
      margin: 0,
      padding: 0
    },
  },
  '@keyframes fadeInInfo': {
    '0%': { opacity: 0 },
    '100%': { opacity: 1 }
  },
  active: {
    fontWeight: 600,
    position: 'relative',
    '&:after': {
      position: 'absolute',
      top: '6px',
      left: 0,
      width: 0,
      height: 0,
      borderTop: '8px solid transparent',
      borderBottom: '8px solid transparent',
      borderLeft: '8px solid #00d8ef',
      content: '""'
    }
  },
  header: {
    display: 'flex',
    textAlign: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    '& img': {
      width: '50%',
      margin: '0 auto'
    },
    '& h1': {
      fontSize: '160%',
      padding: '20px 0 0 0',
      margin: 0
    },
    '& h2': {
      color: '#44545F',
      fontSize: '120%',
      margin: 0,
    },
  },
  navItem: {
    textDecoration: 'none',
    justifyContent: 'space-between',
    color: '#44545F',
    position: 'relative',
    fontSize: '110%',
    padding: '0 24px',
    '&:hover': {
      color: '#44545F',
      fontWeight: 600
    }
  },
  label: {
    '& span': {
      verticalAlign: 'middle',
      lineHeight: '24px',
    },
    '& img': {
      verticalAlign: 'middle',
      lineHeight: '24px',
    }
  },
  '@media (max-width: 700px)': {
    nav: {
      '& h2': {
        textAlign: 'center'
      },
      fontSize: '120%',
      minWidth: '100%',
      width: '100%',
      minHeigth: '100vh'
    }
  },
  '@media (max-width: 400px)': {
    nav: {
      '& h2': {
        textAlign: 'left'
      },
      fontSize: '100%'
    }
  }
}
