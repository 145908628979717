import React from 'react';
import {createUseStyles} from 'react-jss';
import style from './DnaArmband.style';
import imgDna1 from 'static/img/dna1.png';
import imgDna2 from 'static/img/dna2.png';
import imgDna3 from 'static/img/dna3.png';
import imgDna4 from 'static/img/dna4.png';

const useStyles = createUseStyles(style);

export default () => {

  const classes = useStyles();

  return (
    <>
     <p>Fädeln Sie sich ein Armband und lernen Sie dabei, wie ein kleiner Unterschied in der DNA entscheidet, wie gut Milch verdaut werden kann.</p>
     <p>Sie brauchen:</p>
     <ul>
       <li>2 Fäden, jew. ca. 20cm</li>
       <li>Perlen in 4 verschiedenen Farben für die 4 Basen ATCG</li>
       <li>Eine undurchsichtige (z.B.schwarze) Box nur mit Perlen der Basen T und C</li>
     </ul>
      <ul className={classes.wrapper}>
        <li>
          <div className={classes.number}>1</div>
          <div style={{display: 'flex', flexDirection: 'column'}}>
            <p>Nimm dir 2 Fäden. Sie symbolisieren die beiden Chromosomen, auf denen das <strong>Laktase-Gen (MCM6)</strong> samt seiner regulierenden Regionen lokalisiert ist.</p>
            <img src={imgDna1} alt="" />
          </div>
        </li>
        <li>
          <div className={classes.number}>2</div>
          <div style={{display: 'flex', flexDirection: 'column'}}><p>Perlen in vier verschiedenen Farben symbolisieren die vier Basen der DNA:<br />
            <strong style={{color: '#617980'}}>Adenin</strong>, <strong style={{color: '#8DCAB0'}}>Guanin</strong>, <strong style={{color: '#FCD59D'}}>Thymin</strong>, <strong style={{color: '#59C3E0'}}>Cytosin</strong><br />
            In der schwarzen Box befinden sich die Perlen für die <strong>SNP-Stelle</strong>.</p>
            <img src={imgDna2} alt="" />
          </div>
        </li>
        <li>
          <div className={classes.number}>3</div>
          <div style={{display: 'flex', flexDirection: 'column'}}>
            <p>Fädle die Armbänder mit <strong>dieser Sequenz</strong>. An der Stelle mit dem Fragezeichen (<strong>?</strong>) ziehe eine Perle aus der schwarzen Box.<br /> Hier ist der <strong>SNP</strong>, also eine Position, an der unterschiedliche Basen vorliegen können.</p>
            <img src={imgDna3} alt="" />
          </div>
        </li>
        <li>
          <div className={classes.number}>4</div>
          <div style={{display: 'flex', flexDirection: 'column'}}>
            <p>Lege die Armbänder nebeneinander und sieh dir die beiden Perlen an den <strong>?</strong> – Stellen an.<br />Die Basen an der <strong>SNP - Stelle</strong> (LCT<sub style={{fontSize: '50%'}}>-13910C/T</sub>) beeinflussen, ob <strong>Laktose</strong> (Milchzucker) <strong>im Erwachsenenalter verdaut werden kann</strong>.</p>
            <img src={imgDna4} alt="" />
            <p>
              <strong>Welcher Genotyp liegt vor?</strong>
              <div className={classes.typ}><div>T</div><div>T</div></div>
              <div>homozygot: Keine genetische Anlage für Laktoseintoleranz</div>
              <div className={classes.typ}><div>T</div><div>C</div></div>
              <div>heterozygot: Verminderte Laktase-Aktivität im Dünndarm</div>
              <div className={classes.typ}><div>C</div><div>C</div></div>
              <div>homozygot: Genetische Anlage für Laktoseintoleranz</div>
              <strong style={{margin: '16px', textAlign: 'center', display: 'block'}}>Wie gut könnte ein Mensch mit dieser DNA-Sequenz Milch verdauen?</strong>
            </p>
          </div>
        </li>
      </ul>
    </>
  );
};
