import React from 'react';
import {createUseStyles} from 'react-jss';
import imgRead from 'static/img/open-in-new.svg';
import style from './Newspaper.style';

const useStyles = createUseStyles(style);

export default ({newspaperData}) => {
  const { content, intro, description } = newspaperData;
  const classes = useStyles();
  return <>
    {intro && intro}
    {description && <p>{description}</p>}
    <div className={classes.newspaper}>

      {content.map((art, idx) => {
        let {title, newspaper, author, date, link} = art;
        return (
            <a key={'art-lnk-'+idx} href={link} target="_blank" rel="noopener noreferrer" className={classes.article}>
                <div className={classes.header}>
                  <h2>{title}</h2>
                  <span>{author}</span>
                </div>
                <div className={classes.content}>
                  <div className={classes.search}><img src={imgRead} alt="" /></div>
                  {[...Array(Math.floor(Math.random() * 5)+8)].map((__, idex) => <span style={Math.floor(Math.random() * 9) === 2 ? {width: Math.floor(Math.random() * 8)*10+'%'} : {}} key={'np-'-idx+'-'+idex} />)}
                </div>
                <div className={classes.footer}>
                  <span>{date}</span>
                  <span>{newspaper}</span>
                </div>
            </a>
        )
        })}
    </div>
  </>
};
