export default {
  footer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    width: '100%',
    backgroundColor: 'rgba(179, 179, 179, .25)',
    boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
    '& ul': {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'row',
      listStyle: 'none',
      '& li': {
        padding: '16px'
      }
    },
    '&:after': {
      content: '""',
      position: 'absolute',
      left: 0,
      top: 0,
      bottom: 0,
      width: '10px',
      backgroundColor: '#00d8ef'
    }
  },
  txt: {
    padding: '20px 20px 0',
    margin: '0 auto',
    flex: 1,
    fontSize: '85%',
    maxWidth: '800px',
    textAlign: 'center',
    '& a': {
      color: '#44545F',
      textDecoration: 'none',
      '&:hover': {
        color: 'rgb(0, 0, 0)'
      }
    },
    '& ul': {
      margin: 0
    },
    '& li': {
      margin: 0
    }
  },
  logo: {
    margin: '10px 0 0 39px',
    overflow: 'hidden',
    width: '250px',
    minWidth: '250px'
  },
  '@media (max-width: 700px)': {
    footer: {
      flexDirection: 'column',
      '&:after': {
        display: 'none'
      }
    },
    logo: {
      margin: '10px auto',
    }
  }
}
