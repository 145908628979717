import React from 'react';
import {createUseStyles} from 'react-jss';
import imgDiscuss1 from 'static/img/discuss1.png';
import imgDiscuss2 from 'static/img/discuss2.png';
import imgDiscuss3 from 'static/img/discuss3.png';
import imgDiscuss4 from 'static/img/discuss4.png';
import imgRead from 'static/img/open-in-new.svg';

import style from './Discussion.style';
import shuffle from 'knuth-shuffle-seeded';


let imgDiscussSrc = [
  imgDiscuss4,
  imgDiscuss2,
  imgDiscuss3,
  imgDiscuss1
]


const useStyles = createUseStyles(style);

export default ({discussionData, show=false, page}) => {
  const { description, intro, pdf, pdfImg } = discussionData;
  const classes = useStyles();
  let imgDiscuss = shuffle(imgDiscussSrc.slice(0), page.toString());

  return (
    <>
      {intro && <p>{intro}</p>}
      {description.map((txt, idx) => <div key={'b-'+idx} className={classes.bubbleWrapper} style={idx%2 ? {} : {flexDirection: 'row-reverse'}}><div><img src={imgDiscuss[idx]} alt="" style={show ? {} : {animationDelay: idx*.7+'s'}} /></div><div className={idx%2 ? classes.bubble : classes.bubble2} style={{animationDelay: idx*.7+.3+'s'}}>
          {txt}
        </div>
      </div>)}
      {pdf && <a className={classes.link} target="_blank" rel="noopener noreferrer" href={'/pdf/'+pdf} style={show ? {} : {animationDelay: description.length*.7+'s'}}>
        <div className={classes.search}><img src={imgRead} alt="" /></div>
        <img src={pdfImg} alt="" style={{width: '100%'}}/>
      </a>}
    </>

  );
};
