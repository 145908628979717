import React from 'react';
import iconVideo from 'static/img/video.svg';
import iconBook from 'static/img/book.svg';
import iconlist from 'static/img/list.svg';
import iconDiscuss from 'static/img/forum.svg';
import iconNews from 'static/img/newspaper.svg';
import iconDna from 'static/img/dna.svg';
import imgC3 from 'static/img/c3.png';
import imgC1 from 'static/img/c1.png';
import Tooltip from 'components/Tooltip';
import Info from 'components/Tooltip/Info';
export default [
  {
    chapter: 3,
    type: 'preview',
    title: 'Einleitung',
    content: <div style={{fontSize: '90%', backgroundImage: 'linear-gradient(to right bottom, #d0d0d0, #d7d7d7, #dfdfdf, #e6e6e6, #eeeeee)', color: '#555', padding: '16px', margin: 0, borderRadius: '16px'}}>
      <div style={{borderBottom: '1px solid #bbb', padding: '16px 0'}}>
        <div style={{display: 'flex', justifyContent: 'center'}}><img style={{maxWidth: '130px', maxHeight: '130px'}} src={imgC3} alt="" /></div>
        <h1 style={{fontSize: '140%', margin: '24px 0 16px', overflowWrap: 'break-word', wordWrap: 'break-word', textAlign: 'center', color: '#44555f'}}>Daten, Daten, Daten</h1>
      </div>
      <p>Biologische- und Gesundheitsdaten sind das Herzstück der Personalisierten Medizin. </p>
      <p>Sie erfahren in diesem Kapitel:</p>
      <ul style={{padding:0, listStyle: 'none'}}>
      <li style={{display: 'flex', padding: '8px 0'}}><img src={iconlist} style={{margin: '0 8px auto 0'}} alt=""/>Welche Rolle die Informatik in der Personalisierten Medizin spielt.</li>
      <li style={{display: 'flex', padding: '8px 0'}}><img src={iconlist} style={{margin: '0 8px auto 0'}} alt=""/>Wie Gesundheitsdaten in Österreich geschützt werden.</li>
      <li style={{display: 'flex', padding: '8px 0'}}><img src={iconlist} style={{margin: '0 8px auto 0'}} alt=""/>Was mögliche Folgen der datenbasierten Medizin sind.</li>
      </ul>
    </div>
  },
  {
    chapter: 3,
    title: 'Datensammlung',
    navGroup: 'c3g1',
    navIcons: [iconVideo,iconDiscuss],
    type: 'text',
    content: <>
      <p>Die Digitalisierung hat bereits in viele Lebensbereiche Einzug gehalten, so auch in die Medizin. Durch den Einsatz von Biomarkern und genetischen Analysen in der Pr&auml;vention und Diagnostik wird die <strong>Medizin immer datenintensiver</strong>.</p>
      <p>Aber auch in der Forschung spielen computergest&uuml;tzte Methoden zur Verkn&uuml;pfung von Ergebnissen eine gro&szlig;e Rolle. So etwa verknüpfen genomweite Assoziationsstudien (GWAS) im großen Maßstab genetische Varianten mit bestimmten Phänotypen, wie beispielsweise Krankheiten. Dabei entstehen riesige Datenmengen.</p>
      <p>Deshalb wird der Fachbereich der Bioinformatik immer wichtiger. Diese beschäftigt sich mit der Verarbeitung und Interpretation biomedizinischer Daten.<i>[11]</i>.</p>
      <p>Eine <strong>Vision f&uuml;r die Zukunft der Personalisierten Medizin ist</strong> es, alle <strong>Daten einer Person</strong> inklusive ihrer <strong>Genomsequenz elektronisch zu erfassen und sie als &bdquo;digitalen Zwilling&ldquo;</strong> dieses Individuums abzuspeichern. Wenn die Person erkrankt, k&ouml;nnen verschiedene Therapiem&ouml;glichkeiten und ihre Erfolgswahrscheinlichkeiten mit Hilfe der Daten durchgerechnet und die passendste Strategie vorgeschlagen werden.</p>
      <p>Um Biomarker und genetische Profile passenden Wirkstoffen zuordnen zu k&ouml;nnen, ist es wichtig, die Personendaten- also Proben und Laborwerte mitsamt den Informationen zu Krankheitsverlauf und Therapie(miss)erfolg zu sammeln.</p>
      <p>Schon heute werden große Mengen medizinischer Daten in Krankenhausinformationssystemen und Biobanken gespeichert. Durch die weltweite Zusammenarbeit von Mediziner*innen kann in sogenannten Metastudien eine Vielzahl von Forschungsergebnissen kollektiv ausgewertet werden um wertvolle Schlüsse für die Weiterentwicklung von Therapien zu ziehen.</p>
      <p></p>
    </>,
    cit:['11'],
    cittitle: 'Quelle:',
    linkstitle: 'Mehr zum Thema "Biomarker" und "Biobanken" finden Sie hier:',
    chapterLinks: [
      {icon: imgC1, title: [{page: 1, title: 'Grundlagen'}, {page: 11, title: 'Biomarker'}]},
      {icon: imgC1, title: [{page: 1, title: 'Grundlagen'}, {page: 13, title: 'Biobanken'}]}
    ]
  },
  {
    chapter: 3,
    type: 'video',
    isSub: true,
    navGroup: 'c3g1',
    icon: iconVideo,
    title: 'Video: Biologische Daten',
    description: 'Der Informatiker Christoph Bock spricht über biologische Daten in der Personalisierten Medizin.',
    extra: <p>Tipp: Auch die Österreichischen Akademie der Wissenschaften hat Christoph Bock interviewt: <a target="_blank" rel="noopener noreferrer" href="https://www.oeaw.ac.at/detail/news/zellen-sind-quasi-biologische-computer/">zum Artikel</a></p>,
    url: ['https://www.youtube.com/watch?v=7sDbI9ZKOZk']

  },
  {
    chapter: 3,
    type: 'discussion',
    icon: iconDiscuss,
    isSub: true,
    navGroup: 'c3g1',
    title: 'Diskussion: Ihre Blutprobe',
    intro: `Angenommen, Ihnen wird im Rahmen einer Untersuchung in einem Krankenhaus Blut abgenommen. Die Ärztin/der Arzt fragt Sie, ob Sie Ihre Blutprobe für die Sammlung der Biobank des Krankenhauses zur Verfügung stellen.
    Diskutieren Sie auch mit anderen oder lassen Sie sich weitere Argumente einfallen.`,
    description: [
      'Sind Sie bereit, die Probe und die daraus gewonnenen Daten zur Verfügung zu stellen?',
      'Und wenn ja, unter welchen Bedingungen?',
      'Dürfen auch Forscher*innen außerhalb des Krankenhauses darauf zugreifen?',
      'Dürfen die Daten an Unternehmen zur Entwicklung neuer Produkte weitergegeben werden?'
    ],
    infoBox: {
      title: 'Erläuterung',
      content: <p>Wenn Sie Ihre Proben zur Verwendung in einer Biobank freigeben, müssen Sie über die beabsichtigte Probenverwendung informiert werden und dieser zustimmen (informed consent). Die Daten müssen gemäß dem Datenschutzgesetz behandelt werden, und ihre Verwendung kann jederzeit widerrufen werden.</p>
    }
  },
  {
    chapter: 3,
    type: 'text',
    navIcons: [iconDiscuss],
    navGroup: 'c3g2',
    title: 'Schutz von Gesundheitsdaten',
    content: <>
      <p>Daten, die aus einer Befragung oder aus Proben eines*r Patient*in gewonnen werden, sind prinzipiell Eigentum des/der Patient*in.</p>
      <p>Um sie für die konkrete Behandlung einsetzen zu können, müssen aber selbstverständlich auch andere Personen, wie Labordiagnostiker*innen und behandelnde Ärzt*innen, auf die Daten zugreifen können. Aber auch für zukünftige Behandlungen, Behandlungen anderer Patient*innen, die Forschung, sowie Unternehmen mit wirtschaftlichen Interessen können diese Daten wertvoll sein.</p>
      <p>Wer Zugriff auf persönliche Gesundheitsdaten haben darf, ist <strong>gesetzlich geregelt</strong>. Patient*innen können darüber hinaus aber auch den Zugriff auf ihre Daten <strong>aktiv erlauben oder verbieten</strong>.</p>
    </>,
    info: [{
      title: 'Gesetzliche Regelung in Österreich',
      content: <>
        <p>In Österreich regelt die Datenschutzgrundverordnung (DSGVO) im Artikel 9 den Umgang mit personenbezogenen Gesundheitsdaten.<i>[12]</i></p>
        <p>Zusammengefasst besagt sie, dass u.a. die Verarbeitung von genetischen Daten, Gesundheitsdaten und biometrischen Daten zur eindeutigen Identifizierung einer natürlichen Person untersagt ist (DSGVO Art. 9 Abs. 1).</p>
        <p>Ausnahmen gibt es u.a., wenn die Person der Verarbeitung ausdrücklich zustimmt oder sie die Daten selbst öffentlich gemacht hat, wenn es rechtliche Notwendigkeit gibt oder zum Schutz lebenswichtiger Interessen, wenn die Person außerstande ist, ihre Einwilligung zu geben DSGVO Art. 9 Abs. 2). Die personenbezogenen Daten dürfen nur von Fachpersonal, das dem Berufsgeheimnis unterliegt, verarbeitet werden.</p>
      </>,
    }],
    cit:['12']
  },
  {
    chapter: 3,
    type: 'discussion',
    icon: iconDiscuss,
    isSub: true,
    navGroup: 'c3g2',
    title: 'Diskussion: Biomarker in Blutprobe',
    intro: [
      'Stellen Sie sich vor, Sie haben eingewilligt, Ihre Blutprobe in der Biobank zu lagern und sie der Forschung zur Verfügung zu stellen. Drei Jahre später finden Forscher*innen einen Biomarker für eine seltene Krankheit, der auch in Ihrer Blutprobe nachweisbar ist. Als Träger*in dieses Markers können Sie mit einer Wahrscheinlichkeit von 20-40 % im Laufe ihres Lebens daran erkranken.',
      'Wenn diese Krankheit erblich bedingt ist, kann es sein, dass auch Ihre Verwandten diese Genvariante tragen und Sie sie an Ihre eigenen Kinder vererben. Würden Sie Ihren Verwandten davon erzählen? Hätte diese Information einen Einfluss auf Ihre Familienplanung?'
    ],
    description: [
      'Würden Sie gerne informiert werden? Auch wenn es für diese Krankheit derzeit keine Therapie gibt?',
      'Wenn diese Krankheit erblich bedingt ist, kann es sein, dass auch Ihre Verwandten diese Genvariante tragen und Sie sie an Ihre eigenen Kinder vererben könnten. Würden Sie Ihren Verwandten davon erzählen? Hätte diese Information einen Einfluss auf Ihre Familienplanung?'
    ],
    infoBox: {
      title: 'Erläuterung',
      content: <>
        <p>Genetische Analysen ermöglichen es in vielen Fällen, Wahrscheinlichkeiten für den Ausbruch und Verlauf von Krankheiten vorauszusagen. Hier stellt sich nun die Frage, wie weit die Vorbeugung einer Krankheit, die mit einer bestimmten Wahrscheinlichkeit auftritt, gehen kann und soll. </p>
        <p>So könnten schon Kinder auf Genvarianten, die möglicherweise im Erwachsenenalter zu Krankheiten führen werden, untersucht und präventiv behandelt werden. Die vermehrte Präsenz von genetischen Daten und Risikoberechnungen in der Personalisierten Medizin wird dazu führen, dass Gesundheitsdaten auch in der Versorgung gesunder Menschen eine größere Rolle spielen. </p>
        <p>Dies kann einerseits zum Gefühl der Kontrolle über die eigene Gesundheit beitragen und für manche Menschen bestärkend wirken. Andere hingegen könnten sich auf ihre (genetischen) Daten reduziert fühlen und Einschränkungen in ihrer Lebensqualität durch die „Medikalisierung“ des Alltags erleben.</p>
      </>
    }
  },
  {
    chapter: 3,
    type: 'text',
    navGroup: 'c3g3',
    navIcons: [iconDiscuss],
    title: 'Daten-Monitoring',
    content: <>
      <p>Ein weiterer Aspekt der Personalisierten Medizin ist die Kontrolle (Monitoring) des Krankheits- und Therapieverlaufs anhand neuer Informationstechnologien durch die Patient*innen selbst. So können Patient*innen zum Beispiel mit Hilfe von Apps auf ihren Smartphones oder Tablets ihre Gesundheitsdaten täglich aktualisieren.</p>
      <p>Darüber hinaus werden auch immer mehr Geräte entwickelt, die von Patient*innen direkt am Körper getragen werden und Gesundheitsdaten aufzeichnen. Beispiele dafür sind Uhren und Armbänder, die Puls und Körpertemperatur messen, oder Sensoren auf Pflastern, die beispielsweise den Blutzucker messen. Diese Geräte können entweder <strong>von Patient*innen selbst kontrolliert werden</strong>, in Verbindung mit anderen Geräten stehen oder auch kontinuierlich Informationen an ein Gesundheitszentrum senden. Kommt eine bestimmte Konstellation von Daten zusammen, wird die Klinik automatisch elektronisch darüber informiert. Auf diese Weise können Patient*innen in das Monitoring ihrer Behandlung einbezogen werden und ihr Zustand aus der Distanz beobachtet werden.</p>
    </>
  },
  {
    chapter: 3,
    type: 'discussion',
    icon: iconDiscuss,
    isSub: true,
    navGroup: 'c3g3',
    title: 'Diskussion: Gesundheits-Apps',
    description: [
      'Würden Sie Gesundheits-Apps verwenden? Warum schon, warum nicht?',
      'Würden Ihre Großeltern sie verwenden?',
      'Meinen Sie, dass in Zukunft Smartwatches und Chatbots Besuche beim Hausarzt oder der Hausärztin ersetzen könnten?'
    ],
    cit: [16],
    infoBox: {
      title: 'Erläuterung',
      content: <>
        <p>Ob zur Gewichtskontrolle, für Fitnessanwendungen oder zur Diagnostik und Behandlung von Erkrankungen: Die Zahl und Beliebtheit von Gesundheits-Apps nimmt zu.</p>
        <p>Gesundheits-Apps können in der Medizin die Entwicklung von ressourcenschonenden Versorgungsangeboten unterstützen. Eventuell können sie sogar zur Steigerung der Versorgungsqualität beitragen. </p>
        <p>Andererseits bergen Gesundheits-Apps, vor allem bei unreflektiertem Einsatz der Nutzer*innen, auch Risiken. Besonders der Schutz sensibler Gesundheitsdaten wird hier angeführt.  Aufgrund der großen Anzahl verfügbarer Apps ist die Auswahl qualitativ hochwertiger Apps, die Sicherheit und Funktionalität gewährleisten, oft schwierig. Um Apps kritisch bewerten und richtig anwenden zu können, werden Orientierungshilfen zur Einschätzung der Vertrauenswürdigkeit von Gesundheits-Apps gefordert. <i>[16]</i></p>
      </>
    }
  },
  {
    chapter: 3,
    title: 'Mögliche Folgen',
    type: 'video',
    navGroup: 'c3g4',
    icon: iconVideo,
    url: ['https://www.youtube.com/watch?v=z_26AWR57vA'],
    description: <p>Video: Die Politikwissenschaftlerin Barbara Prainsack spricht über politische und ethische Aspekte der Datensammlung in der Personalisierten Medizin</p>,
    infoBox: {
      title: 'Mögliche Folgen datenbasierter Medizin',
      content: <>
        <p>Viele der bisher angesprochenen gesellschaftlichen und ethischen Aspekte betreffen neue Situationen, mit denen Patient*innen in der Personalisierten Medizin konfrontiert werden können. Damit einhergehend wird auch eine verstärkte Rolle der Patient*innen im Prozess der Datensammlung, Aufbewahrung und Freigabe diskutiert.</p>
        <p>Bei selbstständig zu bedienenden Technologien und eigenverantwortlichem Aufzeichnen von Gesundheitsparametern stellt sich wiederum die Frage, wie sich verschiedene Personen in ihrer neuen Rolle zurechtfinden und wer von dieser Situation profitieren kann.</p>
        <p>Apps und Online-Beratungsgespräche ersparen Patient*innen mit körperlichen Beeinträchtigungen lange Wegstrecken, und Übersetzungsdienste bieten einen klaren Vorteil für Personen mit Sprachschwierigkeiten.</p>
        <p>Andererseits stellt die Bedienung von Tablets oder Smartwatches für einige Menschen eine Herausforderung dar und ist für manche Gruppen, wie etwa demente Personen, keine Alternative zu einer persönlichen ärztlichen Betreuung. Auch der Verlust des persönlichen Kontaktes bei medizinischen Beratungen wird hier kritisiert.</p>
      </>,
    },
  },
  {
    chapter: 3,
    type: 'video',
    icon: iconVideo,
    navGroup: 'c3g4',
    isSub: true,
    title: 'Video: Diskussionsanregung',
    url: ['https://www.youtube.com/watch?v=of4oNQhJnfM'],
    infoBox: {
      title: 'Erläuterung',
      content: <>
        <h4>Folgende Personengruppen könnten profitieren:</h4>
        <ul>
          <li>Menschen, für die der persönliche Besuch bei einer medizinischen Einrichtung eine Schwierigkeit darstellt, z.B. Personen mit eingeschränkter Mobilität oder Menschen, die in abgelegenen Gebieten wohnen</li>
          <li>Menschen mit Sprachschwierigkeiten</li>
          <li>Menschen, die keine sozialen Kontakte wollen</li>
        </ul>
        <h4>Die Bedienung von Technologien kann schwierig sein für:</h4>
        <ul>
          <li>Menschen, die keine Erfahrung oder Übung im Umgang mit mobilen Geräten wie Smartphones haben</li>
          <li>Demente Personen</li>
          <li>Menschen die ihre Bewegungen nicht koordinieren können</li>
        </ul>
        <h4>Weitere Risiken:</h4>
        <ul>
          <li>Gefahr der Vereinsamung, wenn persönlicher Kontakt fehlt.</li>
          <li>Personen, die sich solche Geräte nicht leisten können, falls sie nicht von der Krankenkasse gezahlt (oder voll gezahlt) werden.</li>
          <li>Menschen die aufgrund ihrer fordernden Lebenssituation keine Zeit haben, sich mit solchen Technologien auseinanderzusetzen</li>
        </ul>
      </>
    },
  },
  {
    chapter: 3,
    type: 'text',
    title: '(Über)forderung?',
    navGroup: 'c3g5',
    navIcons: [iconDiscuss,iconNews,iconBook],
    content: <>
      <Tooltip />
      <p>Auch eine mögliche Veränderung der Beziehung von Patient*innen zu ihren Ärzt*innen wird im Zusammenhang mit der Personalisierten Medizin viel diskutiert. Wenn in Diagnose- und Behandlungsgesprächen der Fokus vermehrt auf Biomarkern und genetischen Daten liegt, kann dies dazu führen, dass andere Aspekte, die für die Behandlung und Gesundheit von Menschen wichtig sind, ausgeblendet werden.</p>
      <p>Die Berücksichtigung von <strong>Persönlichkeitsaspekten</strong> und sozialen Faktoren, die Einfluss auf Gesundheit und Krankheit haben, könnte in den Hintergrund rücken. Wichtig ist, dass auch in der Personalisierten Medizin das Vertrauen zwischen Patient*innen und Ärzt*innen gefördert wird.  Neben der datenbasierten Behandlung soll auch die <strong>persönliche Komponente</strong> nicht verloren gehen.</p>
      <p>Ein weiteres Thema sind Gesundheitstechnologien, die über den Einsatz in medizinischen Therapien hinausgehen. Auch gesunde Menschen können Interesse daran haben oder dazu aufgefordert werden, ihre Gesundheitsdaten aufzuzeichnen und zu beobachten, um gesund zu bleiben oder Risiken vorzubeugen.</p>
      <p>Diese Eigenverantwortung kann einerseits Menschen darin bestärken, ihre gesundheitliche Zukunft mitzugestalten und zu einer besseren Gesundheitsvorsorge beizutragen. Auf der anderen Seite kann dies dazu führen, dass die Verantwortung für Gesundheitsvorsorge vom Gesundheitssystem immer stärker auf das Individuum selbst übertragen wird. Daraus kann ein <strong>sozialer und politischer Druck</strong> entstehen, Gesundheitsdaten genau aufzuzeichnen und die eigene Gesundheit zu optimieren. Menschen, die sich dieser (Selbst)überwachung im Namen der Gesundheit nicht anschließen wollen oder können, laufen Gefahr, <span className="tooltip" data-tip="Wenn Menschen andere Personen oder Gruppen in bestimmte, negative Kategorien einordnen.">stigmatisiert<Info /></span> und in ihrer Autonomie eingeschränkt zu werden.</p>
      <p style={{marginTop: '32px'}}><strong>Buchtipp: Corpus Delicti: Ein Prozess</strong></p>
      <ul>
        <li>Von Juli Zeh, 2009 im Verlag Schöffling & Co erschienen.</li>
        <li>Der Roman spielt in der Zukunft in einem Überwachungsstaat, der das Ziel verfolgt, seinen Bürger*innen ein gesundes und langes Leben zu ermöglichen. Zur Erreichung des Ziels kontrolliert der Staat die Bürger*innen, eine ungesunde Lebensweise wird bestraft.</li>
      </ul>
      <p style={{marginTop: '32px'}}><strong>Tipp: Artikel in der Presse</strong></p>
      <ul>
        <li><a target="_blank" rel="noopener noreferrer" href="https://www.diepresse.com/5662272/genetische-daten-anstelle-eines-personlichen-gesprachs">Genetische Daten anstelle eines persönlichen Gesprächs?</a></li>
      </ul>
     </>
  },
  {
    chapter: 3,
    type: 'discussion',
    icon: iconDiscuss,
    navGroup: 'c3g5',
    isSub: true,
    title: 'Diskussion: Gesundheitsdaten',
    intro: `Angenommen, Ihnen wird von Ihrer Hausärztin empfohlen, von nun an eine Smartwatch zu tragen, die Ihre Gesundheitsdaten, wie Herzfrequenz, Blutdruck, Fitnesszustand etc. ständig aufzeichnet. Beim nächsten Arztbesuch sollen diese Werte besprochen werden, um Maßnahmen zu setzen, die Ihren Gesundheitszustand optimieren. `,
    description: [
      'Wie fühlen Sie sich bei dieser Vorstellung?',
      'Würde Sie diese Maßnahme bestärken, oder würde sie Sie unter Druck setzen?',
      'Würden Sie Ihre Werte mit Freund*innen besprechen, oder wäre Ihnen das unangenehm?',
      'Würden Sie zustimmen, dass die Werte automatisch an alle Ihre Ärzt*innen übermittelt werden?'
    ]
  },
  {

    chapter: 3,
    type: 'questions',
    icon: iconDna,
    title: 'Quizfrage',
    description: <p>Wie sind Gesundheitsdaten in Österreich geschützt?</p>,
    questions: [
      { question: 'Persönliche Daten sind Eigentum des/ der Patient*in', isTrue: true },
      { question: 'Persönliche Daten sind Eigentum des Krankenhauses' },
      { question: 'Die Daten können genutzt werden, wenn ein/e Patient*in informiert wird und zustimmt', isTrue: true },
      { question: 'Die Datenschutzgrundverordnung regelt den Umgang mit personenbezogenen Gesundheitsdaten', isTrue: true}
    ]
  }
]
