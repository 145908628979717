import React from 'react';
import imgIbu from 'static/img/ibuprofen.png';
import iconlist from 'static/img/list.svg';
import iconDiscuss from 'static/img/forum.svg';
import iconThink from 'static/img/thought-bubble.svg';
import iconVideo from 'static/img/video.svg';
import iconDna from 'static/img/dna.svg';
import iconPDF from 'static/img/pdf.svg';
import iconTxt from 'static/img/form-textbox.svg';
import iconReceipt from 'static/img/receipt.svg';
import imgDnaArmband from 'static/img/DNArmband.jpg';
import imgC1 from 'static/img/c1.png';
import Tooltip from 'components/Tooltip';
import Info from 'components/Tooltip/Info';
export default [
  {
    chapter: 1,
    type: 'preview',
    title: 'Einleitung',
    navIcons: [iconDiscuss],
    navGroup: 'c1g1',
    content: <div style={{fontSize: '90%', backgroundImage: 'linear-gradient(to right bottom, #d0d0d0, #d7d7d7, #dfdfdf, #e6e6e6, #eeeeee)', color: '#555', padding: '16px', margin: 0, borderRadius: '16px'}}>
        <div style={{borderBottom: '1px solid #bbb', padding: '16px 0'}}>
          <div style={{display: 'flex', justifyContent: 'center'}}><img style={{maxWidth: '130px', maxHeight: '130px'}} src={imgC1} alt="" /></div>
          <h1 style={{fontSize: '140%', margin: '24px 0 16px', overflowWrap: 'break-word', wordWrap: 'break-word', textAlign: 'center', color: '#44555f'}}>Grundlagen Personalisierte Medizin</h1>
        </div>
      <p>In diesem Kapitel werden der Begriff und die Vision der Personalisierten Medizin vorgestellt. </p>
      <p>Sie erfahren außerdem:</p>
      <ul style={{padding:0, listStyle: 'none'}}>
        <li style={{display: 'flex', padding: '8px 0'}}><img src={iconlist} style={{margin: '0 8px auto 0'}} alt=""/>Grenzen von Therapien und wie die Personalisierte Medizin ihnen gegenwirken will.</li>
        <li style={{display: 'flex', padding: '8px 0'}}><img src={iconlist} style={{margin: '0 8px auto 0'}} alt=""/>Die Rolle von individuellen Unterschieden für medizinische Behandlungen.</li>
        <li style={{display: 'flex', padding: '8px 0'}}><img src={iconlist} style={{margin: '0 8px auto 0'}} alt=""/>Wie Biomarker in der Krebstherapie eingesetzt werden.</li>
        <li style={{display: 'flex', padding: '8px 0'}}><img src={iconlist} style={{margin: '0 8px auto 0'}} alt=""/>Wie biologische Proben gesammelt werden.</li>
      </ul>
    </div>
  },
  {
    chapter: 1,
    type: 'discussion',
    icon: iconDiscuss,
    title: 'Diskutieren Sie',
    navGroup: 'c1g1',
    isSub: true,
    description: [
      'Was verstehen Sie unter Personalisierter Medizin?',
      'Haben Sie den Begriff schon einmal gehört?'
    ],
    infoBox: {
      title: 'Personalisierte Medizin',
      content: <>
        <Tooltip />
        <p>Im weitesten Sinn bedeutet Personalisierte Medizin die Berücksichtigung individueller Eigenschaften von Patient*innen in der <strong>Diagnose</strong>, <strong>Therapie</strong> und auch in der <strong>Prävention</strong> von Krankheiten.</p>
        <p>Der Mensch steht mit seinen spezifischen Merkmalen im Mittelpunkt - das ist die Vision der Personalisierten Medizin.</p>
        <p>Während der Begriff einen Fokus auf eine persönlichere Betreuung von Patient*innen vermuten lässt, widmet sich die Personalisierte Medizin eigentlich der Berücksichtigung <strong>biochemischer</strong> und <strong>genetischer Eigenschaften</strong> in Diagnose, Therapie, und Prävention. Nichtsdestotrotz haben individuelle Betreuung und ganzheitliche Sorge für die Patient*innen einen wichtigen Platz in der Personalisierten Medizin.</p>
        <p>Als alternative Begriffe für Personalisierte Medizin werden stratifizierte Medizin, zielgerichtete Therapie, <span className="tooltip" data-tip="Diagnose und Therapie von Krankheiten basierend auf dem Erbgut (=Genom) der Patient*innen. Seit die Bestimmung der genauen Abfolge (=Sequenzierung) aller ca. 3 Milliarden Basenpaare des menschlichen Erbguts möglich ist, hat die Genommedizin einen wichtigen Platz in der Medizin eingenommen.">Genommedizin<Info /></span> oder Präzisionsmedizin verwendet.</p>
      </>
    }
  },
  {
    chapter: 1,
    type: 'text',
    title: 'Vision der Personalisierten Medizin',
    content: <>
      <p>Wer heute im Fall einer Erkrankung seine Symptome einem Arzt oder einer Ärztin schildert, bekommt die nach den Erfahrungen des Arztes oder der Ärztin und dem Stand der Wissenschaft bestgeeignete Therapie verschrieben.</p>
      <p>Schlägt diese nicht an oder gibt es starke Nebenwirkungen, wird nach dem Versuch-Irrtum-Prinzip zur nächsten Therapie gewechselt. Dieses Vorgehen ist in manchen Fällen nicht optimal und kann Krankheitsphasen verlängern.</p>
      <p>Die Personalisierte Medizin soll das ändern, indem Medikamente und Behandlungen durch diagnostische Begleittests besser auf bestimmte Patient*innengruppen abgestimmt werden. Im weitesten Sinn bedeutet Personalisierte Medizin also die <strong>Berücksichtigung individueller Eigenschaften von Patient*innen in der Diagnose, Therapie, und auch in der Prävention.</strong></p>
      <p>Die Vision dahinter ist, dass der Mensch mit seinen spezifischen Merkmalen im Mittelpunkt der Medizin steht <i>[1]</i>. Dabei sollen Lösungen jedoch nicht für jeden Menschen einzeln entwickelt werden. Vielmehr werden in der Personalisierten Medizin die verschiedenen, im Menschen vorkommenden genetischen Varianten und molekulare Charakteristika berücksichtigt und Therapien dahingehend ausgewählt.</p>
    </>,
    cit: ['1']
  },
  {
    chapter: 1,
    type: 'text',
    title: 'Grenzen von Therapien',
    navIcons: [iconThink],
    navGroup: 'c1g2',
    content: <>
      <p>Bei vielen Erkrankungen, wie beispielsweise Infektionen, wirken die meisten Standardtherapien relativ gut. Bei <strong>Krankheiten wie Krebs oder Alzheimer</strong> jedoch greifen die Therapien nicht bei allen Menschen gleich gut oder bei manchen Menschen gar nicht. Man spricht hier von <strong>„niedrigen Ansprechraten“</strong>.</p>
      <p>Auf Antidepressiva, Medikamente zur Behandlung von Diabetes und Asthma-Therapeutika sprechen durchschnittlich 60 % aller Patient*innen an, bei Alzheimer-Therapeutika sind es rund 30 % und bei Krebs wirken durchschnittlich nur 20-40 % der Therapeutika.<i>[2]</i></p>
      <p>Therapien, die nicht oder nur schlecht wirken, sind nicht nur f&uuml;r Patient*innen und deren &Auml;rzt*innen ein Problem. Sie f&uuml;hren auch zu einer gro&szlig;en <strong>finanziellen Belastung</strong> des Gesundheitssystems. So z&auml;hlen etwa manche Krebstherapien zu den teuersten Medikamenten.</p>
    </>,
    cit: ['2'],
    cittitle: 'Quelle:'
  },
  {
    chapter: 1,
    type: 'discussion',
    icon: iconThink,
    title: 'Überlegen Sie',
    isSub: true,
    navGroup: 'c1g2',
    description: [
      'Warum können bakterielle Infektionen wie beispielsweise Blasen- oder Augenentzündungen relativ erfolgreich bei allen Menschen gleich behandelt werden?',
      'Warum sind die Ansprechraten auf Medikamente bei Krankheiten wie Alzheimer oder Krebs so gering?'
    ],
    infoBox: {
      title: 'Erläuterung',
      content: <>
      <p>Bei Infektionen müssen die den Körper krankmachenden (pathogenen) Erreger bekämpft werden.</p>
      <p>Das funktioniert, indem man Medikamente verabreicht, die <strong>spezifisch gegen den Erreger</strong> wirken und dem menschlichen K&ouml;rper so wenig wie m&ouml;glich schaden.</p>
      <p>Bei einer bakteriellen Infektion können die Erreger in den meisten Fällen relativ schnell identifiziert werden und, wenn notwendig, spezifisch mit Antibiotika bekämpft werden.</p>
      <p>Bei Krankheiten, die <strong>nicht prim&auml;r von eindringenden Erregern ausgel&ouml;st</strong> werden, sondern durch <strong>Ver&auml;nderungen k&ouml;rpereigener Zellen verursacht</strong> werden, ist eine spezifische Behandlung schwieriger.</p>
      <p>Hier m&uuml;ssen die sch&auml;dlichen Zellen anvisiert, dabei aber die noch gesunden Zellen gesch&uuml;tzt werden. Da jeder Mensch Wirkstoffe unterschiedlich verstoffwechselt und es in manchen F&auml;llen zu Nebenwirkungen kommt, variieren die Ansprechraten auf viele Medikamenten. Krebserkrankungen, denen unterschiedlichen Mutationen zu Grunde liegen, sprechen auf Therapien unterschiedlich an, was die Wirksamkeit von Medikamenten ebenfalls beeinflussen kann.</p>
      </>
    }
  },
  {
    chapter: 1,
    type: 'text',
    title: 'Individuelle Unterschiede',
    navIcons: [iconVideo, iconReceipt],
    navGroup: 'c1g3',
    content: <>
      <p>Der Grund f&uuml;r unterschiedliche Ansprechraten und (Neben)wirkungen sind die <strong>individuellen Unterschiede von Menschen</strong>. Nicht nur Faktoren wie <strong>Alter, Geschlecht oder Gewicht</strong>, sondern auch unser Genom haben einen Einfluss auf die Wirkung von Medikamenten in unserem K&ouml;rper.</p>
      <p>Die meisten Krankheiten entstehen durch ein komplexes Zusammenspiel zwischen genetischen Faktoren und solchen, die auf den Lebensstil zur&uuml;ckzuf&uuml;hren sind.</p>
      <p>Hier einige wichtige Faktoren, die zu unterschiedlichen Wirkungen f&uuml;hren k&ouml;nnen:</p>
    </>,
    info: [
      {
        title: 'Alter',
        content: <>
          <Tooltip />
          <p>Bei &auml;lteren Menschen verlaufen Krankheiten oft anders als bei j&uuml;ngeren Menschen. So etwa sind <span className="tooltip" data-tip="auch: Windpocken; verursacht durch Varizellen-Zoster-Viren">Feuchtblattern<Info /></span>  f&uuml;r Kinder meist harmlos, wohingegen sie bei Erwachsenen einen schweren Verlauf haben k&ouml;nnen, da ihr Immunsystem st&auml;rker reagiert.</p>
          <p>Keuchhusten hingegen kann für Babys und Kleinkinder, die Schleim nicht oder schlechter abhusten können, lebensgefährlich sein. Für Erwachsene ist es eine ernste Erkrankung, aber im Normalfall ist das Erstickungsrisiko gering.</p>
          <p>Im Laufe des Alterns verlangsamt sich der Stoffwechsel, und so m&uuml;ssen bei Senioren*innen Medikamente oft geringer dosiert werden, da Wirkstoffe l&auml;nger im K&ouml;rper bleiben. Weiters h&auml;ngt auch die Art der Verabreichung mit dem Alter zusammen.</p>
          <p>Kleine Kinder und sehr alte Menschen k&ouml;nnen Tabletten oft nicht schlucken, weshalb Wirkstoffe anders zugef&uuml;hrt werden m&uuml;ssen.</p>
        </>
      },
      {
        title: 'Geschlecht',
        content: <>
            <p><strong>M&auml;nner und Frauen</strong> haben durch verschiedene biologische Merkmale <strong>unterschiedliche Risiken</strong>, manche Krankheiten zu bekommen, oder weisen verschiedene Symptome auf.</p>
            <p>So sind Frauen z.B. h&auml;ufiger von Autoimmunerkrankungen betroffen und reagieren mit anderen Symptomen als M&auml;nner auf einen Herzinfarkt. Die Gendermedizin befasst sich mit diesem Thema genauer.</p>
          </>
      },
      {
        title: 'Lebensweise',
        content: <>
          <p>Die Lebensweise hat einen erheblichen Einfluss auf unsere Gesundheit.</p>
          <p><strong>Falsche Ern&auml;hrung</strong> kann beispielsweise zu &Uuml;bergewicht und Diabetes f&uuml;hren, &uuml;berm&auml;&szlig;iger Alkoholkonsum zu Lebersch&auml;den, Bewegungsmangel zu Herz-Kreislauferkrankungen usw.</p>
          <p>Der <strong>allgemeine Gesundheitszustand</strong> beeinflusst, wie gut der K&ouml;rper auf Krankheiten reagieren kann und <strong>wie gut Therapien anschlagen</strong>.</p>
        </>
      },
      {
        title: 'Genetische Varianten',
        content: <>
          <Tooltip />
          <p>Der f&uuml;r die Personalisierte Medizin interessanteste Unterschied zwischen Individuen ist deren genetische Ausstattung. Abgesehen von eineiigen Zwillingen hat <strong>jeder Mensch</strong> ein <strong>einzigartiges Genom</strong>, das sich in ca. 1-2 % vom Genom anderer Menschen unterscheidet.</p>
          <p>Diese Unterschiede betreffen vor allem einzelne Basen, man spricht von &bdquo;single nucleotide polymorphisms&ldquo;, SNPs (sprich &bdquo;snips&ldquo;), sowie Ver&auml;nderungen von DNA-Sequenzabschnitten, sogenannten &bdquo;copy number variations&ldquo;, CNVs. Die meisten dieser Variationen sind medizinisch nicht relevant. Einige Genvarianten stehen aber in Zusammenhang mit einer h&ouml;heren oder geringeren Wahrscheinlichkeit f&uuml;r bestimmte Krankheiten. Varianten einzelner Basen (SNPs) werden zum Beispiel mit einer höheren Wahrscheinlichkeit für die chronische Darmentzündung Morbus Crohn in Verbindung gebracht <i>[3]</i>.</p>
          <p>Andere Genvariationen beeinflussen die Wirkung von Medikamenten im K&ouml;rper:</p>
          <p><strong>Unterschiede in <span className="tooltip" data-tip="die gesamte Erbinformation eines Lebewesens, die in Form von DNA in jeder Zelle gespeichert ist, und Informationen zur Entwicklung und Ausprägung der spezifischen Eigenschaften eines Organismus enthält. Das menschliche Genom mit seinen 30.000 = 40.000 Genen besteht aus 3 Milliarden Basenabfolgen.">Enzymen<Info /></span></strong> bestimmen mit, wie schnell ein Medikament im K&ouml;rper wirkt oder ob es zu Nebenwirkungen f&uuml;hrt.</p>
          <p>Da sich das Erbgut jedes Menschen aus dem seiner Vorfahren zusammensetzt, haben Personen mit gleicher Herkunft oft &auml;hnliche Genvarianten.</p>
          <p>Das zeigt sich beispielsweise an der unterschiedlichen Vertr&auml;glichkeit von <span className="tooltip" data-tip="Milchzucker">Laktose<Info /></span>: Die meisten Europ&auml;er*innen k&ouml;nnen Laktose gut verdauen, w&auml;hrend viele Menschen mit asiatischen Wurzeln eine Laktoseintoleranz aufweisen<i>[4]</i></p>
        </>
      }
    ],
    cit: ['3','4'],
    cittitle: 'Quellen:'
  },
  {
    chapter: 1,
    type: 'video',
    icon: iconVideo,
    navGroup: 'c1g3',
    isSub: true,
    title: 'Videoclip',
    url: ['https://www.youtube.com/watch?v=7VOmMFtIvIg']
  },
  {
    chapter: 1,
    type: 'discussion',
    icon: iconReceipt,
    navGroup: 'c1g3',
    isSub: true,
    title: 'Aufgabe: Beipackzettel',
    description: [
      'Wo und wie werden schon heute diese individuellen Unterschiede von Personen in Behandlungen berücksichtigt?',
      'Schauen Sie sich den Beipackzettel für den Wirkstoff Ibuprofen genauer an.',
      'Finden Sie darin Ansätze von Personalisierung?'
    ],
    pdf: 'Beipackzettel-Ibuprofen.pdf',
    pdfImg: imgIbu,
    infoBox: {
      title: 'Erläuterung',
      content: <>
        <p>Um abschätzen zu können, welche Medikamente in welcher Dosierung am besten wirken, sind persönliche Informationen notwendig. Fragen zu <strong>Allergien, Vorerkrankungen oder Einnahme anderer Medikamente</strong>, die Ärzt*innen im Zuge von Behandlungsgesprächen stellen, bezwecken den zielgerechten Einsatz von Wirkstoffen.</p>
        <p>Auf Beipackzetteln von Medikamenten werden oft Hinweise zur Einnahme unter bestimmten Bedingungen wie Schwangerschaft oder chronischen Erkrankungen angegeben, um auf mögliche Gefahren hinzuweisen.</p>
      </>
    },
  },
  {
    chapter: 1,
    type: 'text',
    title: 'Beispiel: Verträglichkeit von Laktose',
    navGroup: 'c1g4',
    content: <>
      <p>Bis vor ca. 8.000 Jahren konnten erwachsene Menschen keinen Milchzucker (Laktose) verdauen.  Auch heutzutage verliert ein großer Teil der Weltbevölkerung nach dem Abstillen die Fähigkeit, das erforderliche Enzym (Laktase) zu produzieren, um Milch problemlos verdauen zu können. Der unverdaute Milchzucker gelangt in den Dickdarm, wo er von Darmbakterien fermentiert wird, was zu Verdauungsbeschwerden wie Bauchschmerzen, Durchfall oder Blähungen führen kann. Ein großer Teil der Bevölkerung Europas (ca. 85 %), aber auch verschiedene nomadische Völker Afrikas, die Viehzucht betreiben und Milch trinken, vertragen Laktose allerdings. Dies beruht auf Änderungen einzelner Basen im Genom, den bereits genannten single nucleotide polymorphisms (SNPs). WissenschaftlerInnen vermuten hier eine genetische Anpassung an den Lebensstil im Laufe der Evolution. In unterschiedlichen Bevölkerungsgruppen treten verschiedene genetische Varianten auf, die dafür verantwortlich sind, dass Laktase auch im Erwachsenenalter noch in ausreichender Menge produziert wird, um Milch ohne Beschwerden verdauen zu können.</p>
      <p>In Europa am häufigsten verbreitet ist der Austausch einer einzelnen Base an einer Stelle der DNA, die reguliert, ob und wie stark das Produkt des Laktase-Gens (LCT) - also die Laktase - gebildet wird. Diese regulatorische Sequenz befindet sich im Gen MCM6. Wenn sich an der Stelle LCT-13910 Stelle homozygot (also auf beiden Chromosomen) die Base Thymin befindet, kann Laktose üblicherweise auch im Erwachsenenalter verdaut werden. Wenn sich an dieser Stelle homozygot die Base Cytosin befindet, besteht eine große Wahrscheinlichkeit der Laktoseintoleranz. Wenn an dieser Stelle ein Allel die Base Cytosin, das andere Allel die Base Thymin trägt, spricht man von heterozygoten Trägern oder Trägerinnen. Bei ihnen tritt in manchen Fällen eine Laktoseunverträglichkeit auf. </p>
      <table style={{width: '100%'}}>
        <tbody>
          <tr><td><b>LCT<sub style={{fontSize: '70%'}}>-13910</sub> Genotypen</b></td></tr>
        </tbody>
      </table>
      <table style={{width: '100%'}}>
        <tbody>
          <tr><td>TT</td><td>homozygot</td><td>Keine genetische Anlage für Laktoseintoleranz – Normale Laktase Aktivität im Dünndarm</td></tr>
          <tr><td>TC</td><td>heterozygot</td><td>Verminderte Laktase-Aktivität im Dünndarm</td></tr>
          <tr><td>CC</td><td>homozygot</td><td>Genetische Anlage für Laktoseintoleranz - Keine Laktase Aktivität im Dünndarm</td></tr>
        </tbody>
      </table>
    </>,
    info: [{
      title: 'Tipp: DNArmband',
      content: <>
        <p>Fädeln Sie sich ein Armband und lernen Sie dabei: Ein kleiner Unterschied in der DNA entscheidet, wie gut Milch verdaut werden kann.</p>
        <div style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between'}}>
          <div style={{minWidth: '200px', maxWidth: '400px'}}>
            <p>Sie brauchen:</p>
            <ul>
              <li>2 Fäden, jew. ca. 20cm</li>
              <li>Perlen in 4 verschiedenen Farben für die 4 Basen ATCG</li>
              <li>Eine undurchsichtige (z.B.schwarze) Box nur mit Perlen der Basen T und C</li>
            </ul>
            <div style={{display: 'flex', flexDirection: 'column'}}>
              <p style={{padding: 0}}>Anleitung: </p>
              <a style={{width: '40px'}} target="_blank" rel="noopener noreferrer" href={'/pdf/DNArmband.pdf'}>
                <img src={iconPDF} alt="" style={{width: '80px'}}/>
              </a>
            </div>
          </div>
          <div style={{minWidth: '100px', maxWidth: '400px'}}>
            <img style={{width: '100%'}} src={imgDnaArmband} alt="" />
          </div>
        </div>
      </>
    }],
    cit: ['17'],
    cittitle: 'Quelle:'
  },
  {
    chapter: 1,
    type: 'questions',
    icon: iconDna,
    navGroup: 'c1g4',
    isSub: true,
    title: 'Quizfrage',
    description: <p>Wie gut kann eine Person, deren Genotyp an der Stelle LCT <sub style={{fontSize: '70%'}}>-13910</sub> homozygot für Cytosin ist, Milch verdauen?</p>,
    questions: [
      { question: 'Sie kann Milch wahrscheinlich normal verdauen' },
      { question: 'Sie hat mit hoher Wahrscheinlichkeit Probleme, Milch zu verdauen', isTrue: true}
    ]
  },
  {
    chapter: 1,
    type: 'text',
    title: 'Biomarker',
    navGroup: 'c1g5',
    navIcons: [iconVideo],
    content: <>
      <p>Biomarker sind <strong>definierte, messbare, biologische Merkmale</strong>, die Hinweise auf bestimmte k&ouml;rperliche Vorg&auml;nge geben und bei der Diagnose von Krankheiten eine wichtige Rolle spielen.</p>
      <p>Werte wie <strong>K&ouml;rpertemperatur, Blutdruck oder Herzfrequenz</strong> sind Beispiele f&uuml;r diagnostische Biomarker.</p>
      <p>Aber auch auf molekularer Ebene gibt es Biomarker, wie zum Beispiel Proteine, Enzyme und Gensequenzen. Diese Marker k&ouml;nnen in Gewebe- oder Blutproben von Patient*innen nachgewiesen werden und k&ouml;nnen hilfreich sein, eine Krankheit fr&uuml;h zu entdecken (z.B. Diabetes), Risiken von Krankheiten abzusch&auml;tzen (z.B. BRCA-Mutation bei Brustkrebs) oder das Ansprechen auf Therapien sowie das Risiko f&uuml;r Nebenwirkungen vorherzusagen.</p>
      <p>Durch Messung der Wirkstoffkonzentration kann auch kontrolliert werden, ob Patient*innen ihre Medikamente regelm&auml;&szlig;ig einnehmen. Biomarker werden sowohl in der Diagnostik von Krankheiten, der Kontrolle von Therapien als auch im Rahmen von Vorsorgeuntersuchungen als Indikatoren eingesetzt.</p>
    </>
  },
  {
    chapter: 1,
    type: 'video',
    icon: iconVideo,
    navGroup: 'c1g5',
    isSub: true,
    title: 'Video: Biomarker',
    description: 'Amin El-Heliebi, Forscher an der Meduni Graz, erklärt den Einsatz von Biomarkern und Liquid Biopsy in der Krebstherapie.',
    url: ['https://www.youtube.com/watch?v=EO77UZzn3Qo']
  },
  {
    chapter: 1,
    type: 'text',
    title: 'Biobanken',
    navIcons: [iconVideo, iconTxt],
    navGroup: 'c1g6',
    content: <>
      <Tooltip />
      <p>Um Fortschritte in der Forschung zur Personalisierten Medizin zu erm&ouml;glichen, werden Daten und biologische Proben wie Blut oder Gewebe und daraus isolierte Proteine und Nukleins&auml;uren (DNA und RNA) ben&ouml;tigt.</p>
      <p>Diese werden in so genannten Biobanken gesammelt, die meist an medizinische Universit&auml;ten angeschlossen sind. In Biobanken werden <strong>Proben</strong> <strong>mit weiteren Daten</strong> der Spender*innen, wie etwa <strong>Informationen zu Alter, Geschlecht, Krankengeschichte und Lebensstil</strong>, verkn&uuml;pft.</p>
      <h3>Probensammlung für die Forschung</h3>
      <p>Um unerwünschte Rückschlüsse auf die Identität der Spender*innen auszuschließen, werden personenbezogene Daten üblicherweise <span className="tooltip" data-tip="Bei der Pseudonymisierung wird der Name einer Person durch ein anderes Identifikationsmerkmal (Pseudonym), z.B. ein Code aus Buchstaben und Zahlen ersetzt.">pseudonymisiert<Info /></span>. Erkrankte, aber auch Gesunde, werden zunehmend dazu aufgefordert, ihre Daten und Proben in Biobanken f&uuml;r die Forschung und damit zum Wohle der Allgemeinheit zur Verf&uuml;gung zu stellen.</p>
      <p>Die Lagerung dieser Proben und die Speicherung dieser teils sensiblen Gesundheitsdaten erfolgt unter <strong>strengen gesetzlichen Vorgaben und nur mit Zustimmung der jeweiligen Person</strong>.<i>[6]</i></p>
    </>,
    cit: ['6']
  },
  {
    chapter: 1,
    type: 'video',
    icon: iconVideo,
    navGroup: 'c1g6',
    isSub: true,
    title: 'Video: Einblick in eine Biobank',
    description: <>
      <Tooltip />
      <p style={{marginTop: '40px'}}>Die Biobank der <a target="_blank" rel="noopener noreferrer" href="https://biobank.medunigraz.at/">Medizinischen Universität Graz</a> ist die größte Biobank Österreichs. Daneben gibt es noch eine Biobank an der <a href="http://www.biobank.at/" target="_blank" rel="noopener noreferrer">Medizinischen Universität Wien</a> und an der <a href="https://www.i-med.ac.at/biobank/" target="_blank" rel="noopener noreferrer">Medizinischen Universität Innsbruck</a>.</p>
    </>,
    extra: <p>Mit dem Projekt BBMRI.at (Biobanking and Biomolecular Resources Research Infrastructure) beteiligt sich Österreich in einem europäischen Biobankennetzwerk. Zu den Hauptaufgaben von BBMRI.at zählen die Harmonisierung der Prozesse verschiedener Biobanken sowie die Erleichterung des Zugangs zu Biobankproben für Forscher*innen. Eine hohe und definierte Qualität der Proben ist von zentraler Bedeutung, umverlässliche (reproduzierbare) Forschungsergebnisse zu bekommen.<i>[15]</i></p>,
    url: ['https://biobank.medunigraz.at/frontend/user_upload/themen-pr/videos/ORF-steiermark-steirischer-hochschulraum/ORFST_Sth_Biobank.mp4'],
    cit: ['15']
  },
  {
    chapter: 1,
    type: 'textgap',
    icon: iconTxt,
    navGroup: 'c1g6',
    isSub: true,
    title: 'Lückentext'
  },
  {
    chapter: 1,
    type: 'questions',
    icon: iconDna,
    title: 'Quizfrage',
    description: <p>Welche individuellen Unterschiede spielen in der Personalisierten Medizin eine Rolle?</p>,
    questions: [
      { question: 'Geschlecht', isTrue: true },
      { question: 'Genetische Varianten', isTrue: true},
      { question: 'Lebensweise', isTrue: true },
      { question: 'Alter', isTrue: true}
    ]
  }
]
