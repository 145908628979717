import React from 'react';
import { useDrop } from 'react-dnd';
import {createUseStyles} from 'react-jss';
import style from './Quiz.style';

const useStyles = createUseStyles(style);

function selectBackgroundColor(isActive, canDrop) {
  if (isActive) {
    return 'rgba(0, 216, 239,1)'
  } else if (canDrop) {
    return 'rgba(0, 216, 239,.25)'
  } else {
    return '#fff'
  }
}
export default ({ onDrop }) => {
  const classes = useStyles();
  const [{ canDrop, isOver }, drop] = useDrop({
    accept: 'box',
    drop: onDrop,
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  })
  const isActive = canDrop && isOver
  const backgroundColor = selectBackgroundColor(isActive, canDrop)
  return (
    <span ref={drop} className={classes.target} style={{backgroundColor}}></span>
  )
}
