import React from 'react';
import {createUseStyles} from 'react-jss';
import style from './Roleplay.style';
import imgDiscuss1 from 'static/img/discuss1.png';
import imgDiscuss2 from 'static/img/discuss2.png';
import imgDiscuss3 from 'static/img/discuss3.png';
import imgDiscuss4 from 'static/img/discuss4.png';
let imgDiscuss = [
  imgDiscuss2,
  imgDiscuss1,
  imgDiscuss3,
  imgDiscuss4
]
const useStyles = createUseStyles(style);

export default ({roleplayData}) => {
  const { scenarios, description } = roleplayData;
  const classes = useStyles();

  return (
    <>
      <p>{description}</p>
      <ul className={classes.wrapper}>
        {scenarios.map((scenario,idx) => {
          return <li key={'rp-'+idx}>
            <div className={classes.header}>
              <h4>Rolle {idx+1}</h4>
            </div>
            <div className={classes.role}>
              <img src={imgDiscuss[idx]} alt="" />
              <h5>{scenario.role}</h5>
              <p>{scenario.roleDescription}</p>
            </div>
          </li>
        })}
      </ul>
    </>
  );
};
